
import browser from 'helpers/browser'
import Component from 'navigation/component/Component'
import store from 'store/store'

class Toaster extends Component {
  constructor (el) {
    super(...arguments)
    this.bindRefs()
  }

  bindEvents () {
    store.message.listenAndStart(this.onErrorUpdate)
  }

  addMessage ({ text, error }) {
    const div = document.createElement('div')
    const inner = document.createElement('div')
    const close = document.createElement('button')

    close.className = 'toaster__message-close'
    close.onclick = this.hideMessage(div, { close, inner })

    div.className = 'toaster__message initial'
    div.classList.toggle('error', error)

    inner.className = 'toaster__message-inner'
    inner.innerHTML = text

    inner.appendChild(close)
    div.appendChild(inner)
    this.el.appendChild(div)

    browser.waitRepaint(() => {
      const height = inner.offsetHeight
      div.style.setProperty('--height', height + 'px')
      div.classList.remove('initial')
    })

    setTimeout(() => {
      div.style.setProperty('--height', 'auto')
    }, 1000)

    setTimeout(this.hideMessage(div, { close, inner }), 8000)
  }

 hideMessage = (div, { close, inner }) => () => {
   if (!div.parentNode) return

   const height = inner.offsetHeight
   div.style.setProperty('--height', height + 'px')
   close.onclick = null

   browser.waitRepaint(() => {
     div.classList.add('hide')
   })

   setTimeout(() => {
     this.el.removeChild(div)
   }, 1000)
 }

  onErrorUpdate = (message) => {
    if (!message) return

    store.message.value = null
    this.addMessage(message)
  }
}

export default Toaster
