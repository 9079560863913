import Component from 'navigation/component/Component'
import browser from 'helpers/browser'

class Player extends Component {
  deferredInit () {
    this.bindRefs()
    this.resize()
    this.bindModules()
    super.deferredInit()
  }

  bindEvents (add = true) {
    const m = add ? 'addEventListener' : 'removeEventListener'
    this.refs.video[m]('play', this.onVideoPlay)
    this.refs.video[m]('pause', this.onVideoPause)
    this.refs.video[m]('ended', this.onVideoEnded)
    this.refs.playVideo[m]('click', this.onPlayClicked)
    this.refs.muteVideo[m]('click', this.onMuteClicked)
    this.refs.fullscreenVideo[m]('click', this.onFullscreenClicked)
    this.refs.progressVideoWrapper[m]('click', this.onProgressClicked)
    if (add) this.tick()
    else window.cancelAnimationFrame(this.raf)
  }

  updateProgressBar = () => {
    if (!this.refs.video) return
    const ratio = (this.refs.video.currentTime / this.refs.video.duration)
    // console.log(ratio)
    this.refs.progressVideo.style.transform = `translateX(${(ratio - 1) * 100}%)`
  }

  onVideoPause = () => {
    this.el.classList.remove('playing')
  }

  onVideoPlay = () => {
    this.el.classList.add('playing')
  }

  onVideoEnded = (event) => {
    this.el.classList.remove('playing')
  }

  onMuteClicked = (event) => {
    if (this.refs.video.muted) {
      this.refs.video.muted = false
      this.refs.muteVideo.innerHTML = 'SOUND OFF'
    } else {
      this.refs.video.muted = true
      this.refs.muteVideo.innerHTML = 'SOUND ON'
    }
  }

  onProgressClicked =({ pageX }) => {
    const posX = pageX - this.leftOffset,
      max = this.refs.progressVideo.offsetWidth,
      nextTime = (posX / max)
    this.refs.video.currentTime = nextTime * this.refs.video.duration
  }

  resize () {
    super.resize()
    if (!this.refs) return
    this.leftOffset = this.refs.progressVideoWrapper.getBoundingClientRect().left
  }

  onPlayClicked = (event) => {
    if (this.refs.video.paused) this.refs.video.play()
    else this.refs.video.pause()
  }

  onFullscreenClicked = (event) => {
    if (!document.fullscreenElement) browser.requestFullscreen(this.refs.video)
    else browser.exitFullscreen()
  }

  tick = () => {
    this.raf = window.requestAnimationFrame(this.tick)
    this.updateProgressBar()
  }
}

export default Player
