
import MainPage from 'navigation/pages/MainPage'

import ProductDetails from './partials/ProductDetails'
import ProductGallery from './partials/ProductGallery'
import ProductBar from './partials/ProductBar'
import ProductZoom from './partials/ProductZoom'

class Product extends MainPage { 
 static pageName = 'Product'; 
  getModuleMap () {
    const map = super.getModuleMap()
    delete map.form

    return {
      ...map,
      bar: ['.product-bar', ProductBar],
      zoom: ['.product-zoom', ProductZoom],
      details: ['.product-details', ProductDetails],
      gallery: ['.product-gallery', ProductGallery]
    }
  }

  bindModules () {
    super.bindModules()
    this.resize()
  }
}

export default Product
