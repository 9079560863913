
import Component from 'navigation/component/Component'

const getNodeIlnAttribute = (el) => {
  if (el?.hasAttribute('data-iln')) return el
  if (el.parentNode instanceof HTMLElement) return getNodeIlnAttribute(el.parentNode)
  return null
}

const isAnchorElement = (el) => el instanceof HTMLAnchorElement

class ProductCard extends Component {
  constructor () {
    super(...arguments)
    this.modifyEndpoint()
  }

  modifyEndpoint () {
    if (!isAnchorElement(this.el) || !this.el?.hasAttribute('href')) return
    this.url = this.el.getAttribute('href')
    if (this.url.includes('?')) return
    if (this.el.hasAttribute('data-idx')) this.setEndpoint(this.url, 'idx', this.el.getAttribute('data-idx'))
    const nodeIln = getNodeIlnAttribute(this.el)
    if (nodeIln) this.setEndpoint(this.url, 'iln', nodeIln.getAttribute('data-iln'))
    this.el.setAttribute('href', this.url)
  }

  setEndpoint (endpoint, parameter, value) {
    this.url = endpoint + (endpoint.includes('?') ? '&' : '?') + `${parameter}=` + value
  }
}

export default ProductCard
