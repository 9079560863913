
import { throttle, findLastIndex, compact } from 'lodash'

import sizeStore from 'store/sizeStore'
import scroll from 'core/scroll'
import resize from 'helpers/resize'
import Component from 'navigation/component/Component'
import { staggerItems } from 'core/animations'

class ScrollIndicator extends Component {
  constructor (el) {
    super(...arguments)
    this.bindRefs()

    const { scrollIndicatorItems } = this.refs

    if (scrollIndicatorItems && scrollIndicatorItems[0]) {
      scrollIndicatorItems[0].classList.add('current')

      if (scrollIndicatorItems[0].getAttribute('href')) {
        const blocs = compact(scrollIndicatorItems.map(item => {
          const href = item.getAttribute('href')
          return href && el.parentNode.querySelector(href)
        }))
        if (blocs && blocs.length === scrollIndicatorItems.length) this.initWithItems(blocs)
      }
    }
  }

  initWithItems (blocs) {
    this.blocs = blocs
    if (!this.refs.scrollIndicatorItems) return

    this.resize()
    this.onScroll()
  }

  transitionComplete = (event) => {
    this.el.classList.remove('show')
  }

  show () {
    this.el.classList.add('show')
    let stagger = 50

    if (!this.refs.scrollIndicatorItems) return

    if (this.refs.scrollIndicatorItems.length > 10)
      stagger = 20

    return staggerItems(this.refs.scrollIndicatorItems, { initialDelay: 1000, stagger })
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    if (!this.refs.scrollIndicatorItems) return
    this.refs.scrollIndicatorItems.forEach((item) => {
      item[method]('click', this.onClick)
    })
    scroll[add ? 'on' : 'off'](this.onScroll)
  }

  bindModules () {
    super.bindModules()
    this.resize()
  }

  onScroll = throttle(() => {
    if (!this.bounds) return

    const scrollTop = scroll.scrollTop()
    const i = findLastIndex(this.bounds, (top) => scrollTop > top)
    this.refs.scrollIndicatorItems.forEach((item, index) => {
      item.classList.toggle('current', i === index)
    })
  }, 100)

  onClick = event => {
    if (!this.blocs) return

    event && event.preventDefault()
    const index = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget)
    const top = this.blocs[index].offsetTop
    scroll.scrollTo(top)
  }

  resize () {
    super.resize()
    if (!this.blocs) return
    const inc = sizeStore.notificationHeight.get() + sizeStore.headerHeight.get()
    this.bounds = this.blocs.map(bloc => inc + bloc.offsetTop - (resize.height() / 2))
  }
}

export default ScrollIndicator
