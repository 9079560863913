
const findLinks = () => [].slice.call(document.querySelectorAll('[data-event]'))

const getEvent = (el) => JSON.parse(el.getAttribute('data-event') || '{}')

const triggerItem = (item) => {
  const gaEvent = getEvent(item)
  if (window.dataLayer && gaEvent) window.dataLayer.push(gaEvent)
}

const updatePageEvents = () => {
  if (typeof document === 'undefined') return

  findLinks().forEach(item => {
    if (!item.hasTagAttached) {
      const eventName = item.tagName.toUpperCase() === 'FORM' ? 'submit' : 'click'
      item.addEventListener(eventName, (e) => {
        triggerItem(item)
      })
      item.hasTagAttached = true
    }
  })
}

const lastTags = {}

const openPopin = (name, store) => {
  triggerPopin(name, true)
  lastTags[store] = name
}

const closePopin = (store) => {
  if (lastTags[store]) {
    triggerPopin(lastTags[store], false)
    lastTags[store] = null
  }
}

const triggerPopin = (name, state) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'view_popin',
      popin_name: name,
      popin_state: state ? 'open' : 'close'
    })
  }
}

const tag = { updatePageEvents, triggerItem, openPopin, closePopin }

export default tag
