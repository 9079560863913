import { map, get } from 'lodash'
import anime from 'animejs'

import Component from 'navigation/component/Component'
import mqStore from 'store/mqStore'
import browser from 'helpers/browser'
import { staggerItems } from 'core/animations'
import scroll from 'core/scroll'
import resize from 'helpers/resize'

class FilterBar extends Component {
  constructor (el, options) {
    const parent = browser.findParent(el, '.catalog-bar')
    super(parent, options)
    this.opened = false
    this.bindRefs()
    this.filterUrl = this.refs.filterInner.getAttribute('data-filter-url')
    this.parseFilters()
    this.allowEscaping = true
  }

  animation = {
    width: 0,
    height: 0
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.barOverlay[method]('click', this.close)
    this.refs.filterSubmit[method]('click', this.submit)
    document[method]('keydown', this.escape)
    this.refs.filterReset[method]('click', this.reset)
    this.refs.filterToggle.forEach(el => el[method]('click', this.toggle))
    this.refs.filterItems.forEach(el => el[method]('change', this.onFilterUpdate))
    this.refs.filterTitles.forEach(el => el[method]('click', this.onToggleClick))
  }

  escape = event => {
    if (this.allowEscaping && event.key === 'Escape')
      this.close()
  }

  onToggleClick = (event) => {
    const { parentNode } = event.currentTarget
    const opened = parentNode.classList.contains('opened')
    if (opened) return parentNode.classList.remove('opened')
    this.refs.filterTitles.forEach(el => {
      el.parentNode.classList.toggle('opened', el === event.currentTarget)
    })
  }

  onFilterUpdate = ({ currentTarget: checkbox }) => {
    if (checkbox.checked && checkbox.getAttribute('data-exclude') === 'true') {
      this.refs.filterItems.forEach((_checkbox) => {
        if (_checkbox.name === checkbox.name && checkbox.value !== _checkbox.value)
          _checkbox.checked = false
      })
    }

    this.parseFilters()
  }

  parseFilters () {
    const checked = {}
    let empty = true

    this.refs.filterItems.forEach((checkbox) => {
      if (!checkbox.checked) return
      if (!checked[checkbox.name]) checked[checkbox.name] = []
      checked[checkbox.name].push(checkbox.value)
      empty = false
    })
    this.checked = checked

    this.refs.filterCounters.forEach(counter => {
      const key = counter.getAttribute('data-id')
      if (!checked[key]) counter.innerText = ''
      else counter.innerText = `(${checked[key].length})`
    })

    this.refs.filterReset[empty ? 'setAttribute' : 'removeAttribute']('disabled', true)
    const separator = ~this.filterUrl.indexOf('?') ? '&' : '?'
    const url = this.filterUrl + (empty ? '' : (separator + map(checked, (values, key) => `${key}=${values.join(',')}`).join('&')))
    this.refs.filterSubmit.setAttribute('href', url)
  }

  toggle = () => {
    if (this.opened) this.close()
    else this.open()
  }

  submit = () => {
    this.parseFilters()
    this.close()

    // router.navigate(this.submitUrl, true)
  }

  reset = () => {
    this.refs.filterItems.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.parseFilters()
  }

  resizeParent () {
    const parent = get(this, 'options.parent')
    if (!parent) return
    parent.resize()
  }

  open () {
    if (!scroll.locked()) {
      this.locked = true
      const bottom = this.el.nextElementSibling.getBoundingClientRect().bottom - resize.height() + scroll.scrollTop()
      if (scroll.scrollTop() > bottom) scroll.scrollTo(bottom).then(() => scroll.lock(true))
      else scroll.lock(true)
    }

    this.opened = true
    anime.remove(this.el)

    this.el.classList.add('on-top')
    this.el.classList.add('opened')
    this.refs.filterInner.style.display = 'block'
    this.resizeParent()

    const groups = Array.from(this.el.querySelectorAll('.filter-bar__group')).map((el) => {
      return Array.from(el.querySelectorAll('.filter-bar__group-title, .filter-bar-item'))
    })
    groups.push(Array.from(this.el.querySelectorAll('.filter-bar__button')))
    groups.forEach(items => items.forEach(i => { i.style.opacity = 0 }))

    browser.waitRepaint(() => {
      this.options.parent.resize()
    })

    return anime.timeline({
      easing: 'easeInOutCubic',
      targets: this.animation,
      update: this.updateAnimation
    })
      .add({
        width: 1,
        duration: mqStore.tabletPortrait.get() ? 0 : 400
      })
      .add({
        height: 1,
        duration: 600,
        begin: () => {
          groups.forEach(items => staggerItems(items, { initialDelay: 300 }))
        }
      })
      .finished
      .then(() => {
        this.allowEscaping = true
      })
    // this.el.classList.add('opened')
  }

  close = () => {
    this.allowEscaping = false

    if (this.locked) scroll.unlock(true)
    this.locked = false
    this.opened = false
    anime.remove(this.el)

    this.el.classList.remove('opened')

    this.refs.filterTitles.forEach(el => {
      el.parentNode.classList.remove('opened')
    })

    return anime.timeline({
      targets: this.animation,
      update: this.updateAnimation,
      easing: 'easeInOutCubic',
      duration: 300
    })
      .add({ height: 0, duration: 400 })
      .add({ width: 0, duration: mqStore.tabletPortrait.get() ? 0 : 250 })
      .finished
      .then(() => {
        this.el.classList.remove('on-top')
        this.refs.filterInner.style.display = 'none'
      })
  }

  setCount (count) {

  }

  updateAnimation = () => {
    this.el.style.setProperty('--filter-width', this.animation.width)
    this.el.style.setProperty('--filter-height', this.animation.height)
  }

  onButtonClick = (event) => {
    const button = event.currentTarget
    const buttonSlug = button.getAttribute('data-slug')

    this.refs.items.forEach((item) => {
      item.classList.toggle('opened', buttonSlug === item.getAttribute('data-slug'))
    })
  }

  flush () {
    super.flush()
  }
}

export default FilterBar
