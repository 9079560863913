import anime from 'animejs'

import Component from 'navigation/component/Component'
import sizeStore from 'store/sizeStore'

class Notification extends Component {
  constructor () {
    super(...arguments)
    this.resize()
  }

  resize () {
    const height = this.el.offsetHeight
    sizeStore.notificationHeight.set(height)
  }

  show () {
    const timeline = anime.timeline({
      duration: 500,
      delay: 1200
    })

    timeline.add({
      targets: this.el,
      opacity: [0, 1],
      easing: 'linear'
    }, 0)

    timeline.add({
      targets: this.el.firstElementChild,
      translateY: [10, 0],
      easing: 'easeOutCubic'
    }, 0)

    return timeline.finished
  }
}

export default Notification
