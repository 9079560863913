
import { throttle } from 'lodash'

import scroll from 'core/scroll'
import resize from 'helpers/resize'
import InternalPage from 'navigation/pages/InternalPage'
import router from 'core/router'
import browser from 'helpers/browser'
import RevealList from 'components/reveal-list/RevealList'
import MainPage from 'navigation/pages/MainPage'
import MaskReveal from 'components/maskReveal/MaskReveal'
import store from 'store'
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager'

class CategoryProducts extends InternalPage { 
 static pageName = 'CategoryProducts'; 
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.parsePages()
  }

  show () {
    return super.show(...arguments)
  }

  preload (previousPage) {
    return super.preload(previousPage).then(() => {
      if (!store?.lastProduct.get()) return
      const { page } = store.lastProduct.get()
      if (+page === 1) return
      this.preloadingPages = true

      return Array(page - 1).fill(1).reduce((promise, _, i) => {
        return promise.then(() => this.loadNewPage())
      }, Promise.resolve()).then(() => {
        if (!this.modules) this.bindModules()
        this.modules.revealList.reset()
        this.bindRefs()
        router.updatePageLinks()
        this.resize()
      })
    })
  }

  getModuleMap () {
    return {
      revealList: ['.category__list', RevealList],
      maskReveal: ['.mask-reveal', MaskReveal]
    }
  }

  getTranslateY (hide) {
    return MainPage.prototype.getTranslateY.call(this, hide)
  }

  bindEvents (add = true) {
    scroll[add ? 'on' : 'off'](this.onScroll)
    this.refs.products?.forEach(p => p[add ? 'addEventListener' : 'removeEventListener']('click', this.onProductClick))
  }

  onProductClick = (e) => {
    const p = e.currentTarget.firstElementChild
    const id = p.getAttribute('data-id')
    const page = p.getAttribute('data-page')
    const src = this.modules.revealList.el.getAttribute('data-src')

    store.lastProduct.set({ id, page, src })
  }

   onScroll = throttle(() => {
     if (this.bottomList < scroll.scrollTop()) this.loadNewPage()
   }, 1000)

   loadNewPage () {
     if (this.loading || this.currentPage === this.nbPages) return
     this.loading = true

     this.currentPage++

     const link = this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + this.currentPage

     return new Promise(resolve => {
       const xhr = new XMLHttpRequest()
       xhr.withCredentials = true
       xhr.responseType = 'document'
       xhr.open('GET', link, true)
       xhr.setRequestHeader('X-Fursac-Async', 'true')
       xhr.onload = (event) => {
         this.onPageLoaded(event)
         resolve()
       }
       xhr.send()
     })
   }

   onPageLoaded = ({ currentTarget: xhr }) => {
     this.loading = false
     const page = xhr.response
     extractInfoFromXhr(xhr)
     xhr.onload = null

     const items = Array.from(page.querySelectorAll('.category__list .category__text, .category__list .category__product'))
     items.forEach(item => this.refs.categoryList.appendChild(item))
     if (this.preloadingPages) return
     this.modules.revealList.reset()

     router.updatePageLinks()
     browser.waitRepaint(() => {
       this.bindEvents(false)
       this.bindRefs() // a verifier que ça bind bien
       this.bindEvents(true)
       this.resize()
     })
   }

   parsePages () {
     this.currentPage = +this.refs.categoryList.getAttribute('data-page')
     this.nbPages = +this.refs.categoryList.getAttribute('data-nb-pages')
     this.endpoint = this.refs.categoryList.getAttribute('data-src')
   }

   resize () {
     super.resize()
     const offset = resize.height() * 3
     const bottom = browser.offsetTop(this.refs.categoryList) + this.refs.categoryList.offsetHeight
     this.bottomList = bottom - resize.height() - offset
     //  this.bottomList = this.refs.categoryList.getBoundingClientRect().bottom + scroll.scrollTop() - resize.height() - offset
   }
}

export default CategoryProducts
