
import { toPairs, now, reduce, sortBy } from 'lodash-es'
import anime from 'animejs'
import Inrtia from 'inrtia'

import Component from 'navigation/component/Component'
import scroll from 'core/scroll'
import router from 'core/router'
import detect from 'helpers/detect'
import math from 'helpers/math'
import browser from 'helpers/browser'

const SPEED = 1.2

class Carousel extends Component {
  constructor (container, items = null) {
    super()
    this.el = container
    this.items = this.getItems()
    this.step = { current: 0 }
    this.shift = 0
    this.total = this.items.length
    this.scrollLocked = false

    this.resize()

    this.inrtia = new Inrtia({
      value: this.step.current,
      precision: 0.1,
      perfectStop: true,
      friction: !detect.touch ? 10 : 3
    })

    this.selectStep(0)
    this.updatePosition(true)
  }

  getItems () {
    return Array.from(this.el.children)
  }

  enableKeyboard () {
    this._keyboardEnabled = true
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method](!detect.touch ? 'mousedown' : 'touchstart', this.mousedown)
    window[method](!detect.touch ? 'mouseup' : 'touchend', this.mouseup)
    window[method](!detect.touch ? 'mousemove' : 'touchmove', this.mousemove, { passive: false })
    window[method]('keydown', this.onKeyDown)
  }

  onKeyDown = ({ key }) => {
    if (!this._enabled || !this._keyboardEnabled) return
    if (key === 'ArrowLeft') this.prev()
    else if (key === 'ArrowRight') this.next()
  }

  enable () {
    this.tick()
    this._enabled = true
    this.resize()
  }

  disable () {
    window.cancelAnimationFrame(this.raf)
    this._enabled = false
  }

  tick = () => {
    this.updatePosition()
    this.raf = window.requestAnimationFrame(this.tick)
  }

  prev = (force = false) => {
    const method = force ? math.wrap : math.clamp
    const step = method(this.step.current - this.offset, 0, this.end + (force ? 0 : -1))
    this.frames = false

    // this.inrtia.to(this.steps[step])
    this.animateToStep(step)
  }

  next = (force = false) => {
    const method = force ? math.wrap : math.clamp
    const step = method(this.step.current + this.offset, 0, this.end + (force ? 0 : -1))
    this.frames = false

    // this.inrtia.to(this.steps[step])
    this.animateToStep(step)
  }

  goTo = (rawStep, force = false) => {
    if (rawStep === this.step.current && force === false) return
    const step = this.steps[+rawStep]
    this.selectStep(+rawStep)
    this.inrtia.stop()
    this.inrtia.value = step
    this.inrtia.targetValue = step
    this.updatePosition(true)
  }

  animateToStep = (rawStep) => {
    if (rawStep === this.step.current) return
    this.animateTo(this.steps[rawStep])
  }

  animateTo = (newValue) => {
    this.animating = true
    this.inrtia.stop()
    this.findStepIndex(newValue, true)
    anime.remove(this.inrtia)
    const { value } = this.inrtia

    return anime({
      targets: this.inrtia,
      value: [value, newValue],
      targetValue: [value, newValue],
      easing: this.easing,
      duration: this.duration,
      complete: () => this.updatePosition(true),
      change: () => this.updatePosition(true)
    }).finished.then(() => {
      this.animating = false
      this.emit('complete', this.step.current)
    })
  }

  duration = 450
  easing = 'easeOutQuad'

  getFrame (event) {
    return {
      time: now(),
      x: event.pageX,
      y: (event.pageY - scroll.scrollTop()),
      value: this.inrtia.value
    }
  }

  mousedown = (event) => {
    if (this._enabled === false) return

    event = browser.mouseEvent(event)
    const frame = this.getFrame(event)
    this.scrollLocked = false

    this.frames = {
      first: frame,
      history: [frame]
    }
    anime.remove(this.inrtia)
    this.el.classList.add('grabbing')
    this.inrtia.stop()
  }

  mousemove = (event) => {
    if (this._enabled === false || !this.frames) return

    const mouseEvent = browser.mouseEvent(event)

    const frame = this.getFrame(mouseEvent)
    const { first } = this.frames

    const distance = frame.x - first.x

    if (this.scrollLocked || this.hasScrolled(frame, first)) event.preventDefault()
    else return

    if (this.prevent) event.preventDefault()
    event && event.stopImmediatePropagation()

    const value = first.value + (distance * SPEED)
    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))

    this.frames.history.push(frame)
    while (this.frames.history.length > 5) this.frames.history.shift()

    this.inrtia.to(clampedValue)
  }

  mouseup = (event) => {
    if (this._enabled === false || !this.frames) return

    event && event.cancelable && event.preventDefault()
    event && event.stopImmediatePropagation()
    event = browser.mouseEvent(event)

    const frame = this.getFrame(event)
    const { first, history } = this.frames
    const last = history[0]

    if (this.hasClicked(frame, first)) this.click(event, event.target)

    if (!this.frames) return // recheck here

    const distance = frame.x - first.x
    const value = first.value + (distance * SPEED)
    const speed = (frame.x - last.x) / (frame.time - last.time)

    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))
    let index = this.findStepIndex(clampedValue)

    if (index === this.step.current && Math.abs(speed) > 0.05) {
      if (speed > 0 && index > 0) index--
      if (speed < 0 && index <= this.total - 1) index++
    }

    // const stepValue = this.steps[index]
    this.selectStep(index)
    this.animateTo(this.steps[index])
    // this.inrtia.to(newValue)
    this.frames = false
    this.el.classList.remove('grabbing')
  }

  hasClicked (frame, first) {
    const distanceX = frame.x - first.x
    const distanceY = frame.y - first.y
    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
    const delay = frame.time - first.time
    return (delay < 300) && (Math.abs(distance) < 30)
  }

  hasScrolled (frame, first) {
    const distanceX = frame.x - first.x
    const distanceY = frame.y - first.y
    const angle = Math.atan2(distanceY, distanceX) * (180 / Math.PI)
    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)

    const range = 30
    const gap = Math.abs(90 - Math.abs(angle))
    const horizontal = gap >= 90 - range

    if (distance > 50) {
      if (horizontal) this.scrollLocked = true
      else this.frames = null
    }

    return horizontal
  }

  click (event, target) {
    const link = target.getAttribute('data-navigo') ? target : target.querySelector('a[data-navigo]')
    if (!link) return this.emit('click', event)
    const href = router.getLinkPath(link)
    router.navigate(href)
  }

  reset () {
    if (!this.inrtia) return
    const newValue = this.steps[this.step.current]
    if (newValue === this.inrtia.targetValue) return
    this.inrtia.to(newValue)
  }

  findStepIndex (value, select = false) {
    const sorted = sortBy(toPairs(this.steps), (step, i) => Math.abs(value - step[1]))
    const index = parseInt(sorted[0][0])
    if (select) this.selectStep(index)
    return index
  }

  findStep (value, select) {
    const index = this.findStepIndex(value, select)
    return this.steps[index]
  }

  updatePosition = (force) => {
    if (this._enabled === false) return
    if (this.inrtia.stopped && force !== true) return
    const value = this.inrtia.update() + this.shift
    this.el.style.transform = `translate3d(${value}px, 0, 0)`
    // this.el.style.transform = `translateX(${value}px)`
  }

  selectStep (step) {
    if (step === this.step.current) return
    this.step.current = step
    this.emit('update', step)
  }

  resize () {
    super.resize()

    // const outer = this.el.parentNode
    // const outerWidth = outer.offsetWidth
    // const innerWidth = this.el.offsetWidth
    // const outerPadding = this.el.offsetLeft
    // const innerPadding = this.items[0].offsetLeft

    // let max = 0

    // if (outerWidth - (innerPadding * 2) < innerWidth) {
    //   const difference = (innerWidth + innerPadding) - (outerWidth - (outerPadding * 2))
    //   max = -Math.max(0, difference)
    // }

    this.end = this.items.length

    this.steps = reduce(this.items, (memo, li, i) => {
      let w = 0
      const s = window.getComputedStyle(li)
      w = -(li.offsetWidth + parseInt(s.marginLeft) + parseInt(s.marginRight))
      w += memo[i]

      // if (w <= max) this.end = Math.min((i + 1), this.end)
      // memo.push(Math.max(max, w))
      if (i < this.end - 1)
        memo.push(w)
      return memo
    }, [0])

    this.minStep = this.steps[0]
    this.maxStep = this.steps[this.steps.length - 1]
    this.offset = Math.max(1, (this.items.length - this.end) + 1)

    const lastAttribute = this.el.getAttribute('style', '')
    if (this._enabled === false) this.el.setAttribute('style', '')
    else if (lastAttribute === '') this.reset()

    if (this.inrtia) this.updatePosition(true)
  }

  flush () {
    super.flush()
  }
}

export default Carousel
