import Navigo from 'navigo'

import Config from 'core/config'
import store from 'store/store'

import tag from './tag'

// const buildRoot = (lang) => Config.baseurl
const buildRoot = (lang) => Config.baseurl + '/' + lang

const router = new Navigo(buildRoot(Config.lang), false, false)
router.path = () => window.location.href
router.uri = () => window.location.pathname + window.location.search

if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

window.updateLang = (lang) => {
  if (lang === Config.lang) return
  Config.lang = lang
  router.root = buildRoot(Config.lang)
  router.lastRouteResolved().url = ''
}

const history = []

router.hooks({
  before: (resolve) => {
    const [path, hash] = router.path().split('#')
    const [currentPath, currentHash] = (router.currentPage() || '').split('#')
    const isSamePage = currentPath === path && hash !== currentHash

    history.push(router.path())

    if (isSamePage) return resolve(false)

    if (history.length > 1) {
      store.search.set(null)
      store.panel.set(null)
      store.menu.set(null)
    }

    store.path.set(router.path().replace(router.root, ''))

    resolve()
  },
  after: () => {
    // history.push(router.path())
  }
})

router.history = () => history
router.lastPage = () => history.length > 1 ? history[history.length - 2] : false
router.currentPage = () => history.length > 0 ? history[history.length - 1] : false

router._findLinks = () => [].slice.call(document.querySelectorAll('[data-navigo], .data-navigo'))
router.getLinkPath = (link) => {
  return link.getAttribute('href').replace(router.root, '')
}

router.updatePageLinks = () => {
  if (typeof document === 'undefined') return

  tag.updatePageEvents()

  router._findLinks().forEach(link => {
    if (!link.hasListenerAttached) {
      link.addEventListener('click', (e) => {
        if ((e.ctrlKey || e.metaKey) && e.currentTarget.tagName.toLowerCase() === 'a') return false
        // if ((e.ctrlKey || e.metaKey) && e.target.tagName.toLowerCase() === 'a') return false
        const location = router.getLinkPath(link)

        if (!router._destroyed) {
          e.preventDefault()
          router.navigate(location.replace(/\/+$/, '').replace(/^\/+/, '/'))
        }
      })
      link.hasListenerAttached = true
    }
  })
}

export default router
