
import Component from 'navigation/component/Component'
import store from 'store/store'

class CheckoutBreadcrumb extends Component {
  bindEvents (add = true) {
    const method = add ? 'listenAndStart' : 'unlisten'
    store.checkoutStep[method](this.onStepUpdate)
  }

  onStepUpdate = (stepNumber) => {
    Array.from(this.el.children).forEach((step, i) => {
      step.classList.toggle('current', (i + 1) === stepNumber)
    })
  }
}

export default CheckoutBreadcrumb
