
import Component from 'navigation/component/Component'

class PasswordField extends Component {
  constructor (el, { parent }) {
    super(el)
    this.el.style.textTransform = 'none'
    this.button = this.el.parentNode.querySelector('.field__show')
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.button[method]('click', this.onClick)
  }

  onClick = (e) => {
    e.preventDefault()
    const show = this.el.type === 'password'
    this.el.type = show ? 'text' : 'password'
    this.button.innerText = this.button.getAttribute('data-label').split('|')[show ? 1 : 0]
  }
}

export default PasswordField
