import { defer } from 'lodash-es'
import anime from 'animejs'

import scroll from 'core/scroll'
import store from 'store'
import mqStore from 'store/mqStore'

import Page from './Page'

export default class MainPage extends Page { 
 static pageName = 'MainPage'; 
  /* LOAD */
  prepare () {
    this.el.classList.add('appearing')
    store.loading.set(0.33)

    return super.prepare(...arguments)
      .then(() => {
        store.loading.set(1)
      })
  }

  loadImage (img, i, total) {
    const inc = .67 / total

    return super.loadImage(...arguments)
      .then(() => {
        store.loading.set(store.loading.value + inc)
      })
  }

  getTranslateY (hide) {
    return hide ? '-50vh' : '50vh'
    // else return hide ? '-1000rem' : '1000rem'
  }

  /* SHOW */
  show (previousPage) {
    this.resize()
    scroll.lock()

    const easing = 'easeInOutCubic'
    const delay = previousPage ? 100 : 800

    return Promise.resolve()
      .then(() => anime({
        targets: this.el,
        translateY: {
          value: [this.getTranslateY(), 0],
          easing,
          // round: 1,
          duration: 1200
        },
        opacity: {
          value: [0, 1],
          duration: 700,
          delay: delay + 500,
          easing: 'linear'
        },
        delay,
        changeBegin: () => this.showChildren()
      }).finished)
      .then(() => {
        scroll.unlock()
        this.el.style.transform = ''
        defer(() => this.resize())
      })
  }

  getChildrenShowDelay () {
    return 1
  }

  showChildren () {
    setTimeout(() => this.invokeShow(), this.getChildrenShowDelay())
  }

  transitionComplete () {
    this.el.classList.remove('appearing')
    super.transitionComplete()
  }

  /* HIDE */

  hide (nextPage) {
    this.el.classList.add('disappearing')
    const opacityDuration = 500

    return anime({
      targets: this.el,
      translateY: {
        value: [0, this.getTranslateY(true)],
        easing: 'easeInOutCubic',
        duration: 1200
      },
      opacity: {
        value: [1, 0],
        easing: 'linear',
        duration: opacityDuration
      },
      begin: () => {
        // setTimeout(() => {
        //   this.el.display = 'none'
        // }, opacityDuration + 100)
      },
      delay: 100
    }).finished
  }
}
