import Component from 'navigation/component/Component'

class ButtonRect extends Component {
  constructor (el, blocs) {
    super(...arguments)
    this.buildSpan()
  }

  buildSpan () {
    this.el.setAttribute('data-hover', this.el.textContent)
    this.span = document.createElement('span')
    this.span.innerHTML = this.el.innerHTML
    this.span.classList.add('button-rect__inner')
    this.el.innerHTML = ''
    this.el.appendChild(this.span)
  }
}

export default ButtonRect
