
import Component from 'navigation/component/Component'

class ToggleWebstoreSwitcher extends Component {
  bindEvents (add = true) {
    this.el[add ? 'addEventListener' : 'removeEventListener']('click', this.onClick)
  }

  onClick = () => {
    const wss = document.querySelector('.webstore-switcher')
    if (!wss) return
    const isHidden = wss.hasAttribute('hidden')
    if (!isHidden) wss.setAttribute('hidden', '')
    else wss.removeAttribute('hidden')
  }
}

export default ToggleWebstoreSwitcher
