import { compact } from 'lodash'

import Map from 'components/map/Map'
import scroll from 'core/scroll'
import InternalPage from 'navigation/pages/InternalPage'
import InternalRouter from 'navigation/pages/InternalRouter'
import MainPage from 'navigation/pages/MainPage'
import store from 'store'

import CheckoutPickupInner from './CheckoutPickupInner'

class CheckoutPickup extends InternalRouter { 
 static pageName = 'CheckoutPickup'; 
  constructor (el) {
    super(...arguments)
    this.bindRefs()
  }

  getDefaultPageClass () {
    return CheckoutPickupInner
  }

  getModuleMap () {
    return {
      ...MainPage.prototype.getModuleMap.call(this),
      //    addressInput: ['input[autocomplete="street-address"]', AddressField],
      map: ['.pickup__map-inner', Map]
    }
  }

  show () {
    return InternalPage.prototype.show.call(this, ...arguments)
  }

  hide () {
    return InternalPage.prototype.hide.call(this, ...arguments)
  }

  getPageSelector () {
    return '.pickup__list'
  }

  bindModules () {
    super.bindModules(...arguments)
  }

  onMapClicked = (i) => {
    if (!this.items || !this.items[i]) return
    const scrollTop = this.items[i].offsetTop
    scroll.scrollTo(scrollTop, { target: this.el.parentNode })
  }

  onPageShown = (page) => {
    this.modules.form.setSubmitted(false)

    this.items = (page.refs.pickupAddress || [])

    const points = this.items.map((el, i) => {
      const p = el.getAttribute('data-coords').split(',').map(a => +a)
      const text = page.refs.addressName[i].innerHTML
      let button = null

      if (el.getAttribute('data-closed') !== 'true') {
        const originalButton = el.querySelector('button')
        button = document.createElement('button')
        button.innerHTML = originalButton.innerHTML
        button.setAttribute('data-id', originalButton.getAttribute('data-id'))
        button.addEventListener('click', this.onMarkerSelected)
        button.className = 'map__popin-button button-rect'
      }

      return [p, text, button]
    })

    this.modules.map.setMarkers(points)
    this.bindRefs()
  }

  onMarkerSelected = ({ currentTarget, preventDefault }) => {
    this.pageManager.store.set(null)
    store.checkoutPickup.set(currentTarget.getAttribute('data-id'))
  }

  scrollToTop (cb) {
    const current = this.el.parentNode.scrollTop
    if (current > 0) scroll.scrollTo(0, { target: this.el.parentNode }).then(cb)
    else cb()
  }

  onAddressSelected = ({ address, city, countryName }) => {
    this.modules.addressInput.el.value = compact([address, city, countryName]).join(', ')
    this.modules.form.submit()
  }

  bindEvents (add = true) {
    const methodSignal = add ? 'on' : 'off'
    this.modules.content[methodSignal]('show', this.onPageShown)
    this.modules.map[methodSignal]('clicked', this.onMapClicked)
  }
}

export default CheckoutPickup
