import { memoize } from 'lodash-es'

const requestFullscreen = (el) => {
  if (el.requestFullscreen) el.requestFullscreen()
  else if (el.webkitRequestFullscreen) el.webkitRequestFullscreen()
  else if (el.mozRequestFullScreen) el.mozRequestFullScreen()
  else if (el.msRequestFullscreen) el.msRequestFullscreen()
  else if (el.webkitEnterFullScreen) el.webkitEnterFullScreen()
}

const exitFullscreen = () => {
  if (document.exitFullscreen) document.exitFullscreen()
  else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
  else if (document.mozCancelFullScreen) document.mozRequestFullScreen()
  else if (document.msExitFullscreen) document.msExitFullscreen()
}

const mouseEvent = (event) => {
  if (event.originalEvent) event = event.originalEvent

  if (event && event.touches && event.touches.length > 0)
    return event.touches[0]
  else if (event && event.changedTouches && event.changedTouches.length)
    return event.changedTouches[0]

  return event
}

const waitRepaint = (cb) => {
  const o = {}
  o.raf = window.requestAnimationFrame(() => {
    o.raf = window.requestAnimationFrame(cb)
  })
  return o
}

const webp = memoize(() => {
  const elem = document.createElement('canvas')

  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  }

  // very old browser like IE 8, canvas not supported
  return false
})

const bindAll = (methods, that) => {
  methods.forEach(method => (that[method] = that[method].bind(that)))
}

const findParent = (elem, selector) => {
  for (;elem && elem !== document; elem = elem.parentNode)
    if (elem.matches(selector)) return elem
}

const isParent = (elem, parent) => {
  for (;elem && elem !== document; elem = elem.parentNode)
    if (elem === parent) return true
  return false
}

const prepareVideo = (video) => {
  video.setAttribute('muted', '')
  video.setAttribute('playsinline', '')
  video.setAttribute('webkit-playsinline', '')
  video.muted = true
  video.playsinline = true
  video.webkitPlaysinline = true
}

const resetElement = (element) => {
  const clone = element.cloneNode(true)
  element.parentNode.insertBefore(clone, element)
  element.parentNode.removeChild(element)
  return clone
}

const offsetTop = function (el, customOffset = false) {
  let offset = el.offsetTop
  if (el.offsetParent)
    offset += offsetTop(el.offsetParent, customOffset)

  if (customOffset) offset += customOffset(el) || 0

  return offset
}

const local = () => window && window.location.port >= 8000

export default {
  requestFullscreen,
  exitFullscreen,
  offsetTop,
  prepareVideo,
  resetElement,
  mouseEvent,
  findParent,
  isParent,
  bindAll,
  waitRepaint,
  webp,
  local
}
