
import { defer, memoize } from 'lodash-es'
import Emitter from 'tiny-emitter'

import router from 'core/router'
import store from 'store'

const pageMap = memoize(() => require('core/pageMap').default)

class PageManager extends Emitter {
  state = { transitioning: false, loading: false, previous: false, next: false, init: false }
  page = { current: null, previous: null }
  main = true

  constructor (container, pageSelector, defaultPageClass, parameters = {}, { loadBeforeHide = true, crossTransition = true } = {}) {
    super()

    // Page Parameters
    if (!parameters.pageManager) parameters.pageManager = this
    this.parameters = parameters

    // Transition Parameters
    this.loadBeforeHide = loadBeforeHide
    this.crossTransition = crossTransition

    // Manager Parameters
    this.container = container
    this.defaultPageClass = defaultPageClass
    this.pageSelector = pageSelector

    this.initializeRoutes()
  }

  initializePage (pathName) {
    if (this.main) {
      this.title = document.querySelector('title')
      this.bodyClassName = document.body.className || ''
      this.pathName = ('' + document.location).replace(router.root, '')
      if (this.bodyClassName.indexOf('error404') > -1) pathName = '404'
    }

    const el = this.extractPage(this.container)

    this.page.current = this.createPage(
      el,
      this.getPageClass(el)
    )

    const prev = this.state.previous || false

    Promise.resolve()
      .then(() => this.page.current?.prepare(prev))
      .then(() => {
        this.state.init = true
        this.emit('init')
      })
      .then(() => new Promise(resolve => {
        defer(() => {
          resolve()
          this.emit('show', this.page.current)
        })
      }))
      .then(() => this.page.current.askShow(prev))
      .then(() => this.page.current.transitionComplete())

    router.updatePageLinks()
  }

  initializeRoutes (routes) {
    router.on('**', () => this.onRouteUpdate(router.path()))
    defer(() => router.resolve())
  }

  virtual (pathName, requestOptions) {
    this.emit('virtual', pathName)
    this.rewriteRoute(pathName, false)
    this.onRouteUpdate(pathName, requestOptions)
  }

  rewriteRoute (pathName, overwrite = true, absolute = true) {
    if (overwrite) {
      router.pause()
      router.lastRouteResolved().url = pathName
      router.navigate(pathName, absolute)
      router.resume()
    } else {
      if (pathName === this.newPathName) return
      this.disabled = true
      router.navigate(pathName, absolute)
      this.disabled = false
    }
  }

  inject (pathName, xhr, requestOptions = {}) {
    const cb = () => {
      this.rewriteRoute(pathName, false)
      this.pageLoaded(pathName, xhr, requestOptions)
    }

    if (this.state.transitioning || this.state.loading) this.once('shown', cb)
    else cb()
  }

  navigateTo (pathName) {
    router.navigate(pathName, true)
  }

  onRouteUpdate (pathName, requestOptions = {}) {
    if (this.disabled) return
    if (!this.page.current && this.extractPage(this.container) && !this.page.previous) return this.initializePage(...arguments)

    pathName = pathName.split('#')[0]

    if (this.state.transitioning || this.state.loading) {
      this.rewriteRoute(this.newPathName)
      this.waitingForPage = pathName
      return
    }

    this.newPathName = pathName
    this.emit('navigate', pathName)

    if (!this.loadBeforeHide) {
      if (this.page.current) this.page.previous = this.page.current
      if (this.page.previous) this.hidePage()
    }

    this.emit('loading')
    this.load(pathName, requestOptions)
    return true
  }

  load (pathName, { method = 'GET', body, ...options } = {}) {
    if (this.xhr) {
      this.xhr.onload = this.xhr.onerror = null
      this.xhr.abort()
    }

    this.state.loading = true
    this.xhr = new XMLHttpRequest()
    this.xhr.withCredentials = true
    this.xhr.open(method, pathName, true)
    this.xhr.setRequestHeader('X-Fursac-Ajax', true)

    if (body) this.xhr.body = body
    this.xhr.responseType = 'document'
    this.xhr.onload = () => {
      this.pageLoaded(pathName, this.xhr, options)
    }
    this.xhr.send(body)
  }

  triggerInternalRouting (pathName, xhr, requestOptions) {
    requestOptions.rewriteRoute = this.rewriteRoute.bind(this)
    this.state.loading = false
    this.page.current.internalRouting(...arguments)
  }

  cancelTransition () {
    this.rewriteRoute(this.pathName, true, false)
    this.state.loading = false
  }

  pageLoaded (pathName, xhr, requestOptions) {
    const page = xhr.response
    const el = this.extractPageFromXHR(page.body)

    // DETECT ERROR
    if (!el) {
      document.documentElement.innerHTML = page.documentElement.innerHTML // Unhandled errors
      return
    }

    // INJECT IN ANOTHER ROUTER
    if (el.hasAttribute('data-router')) {
      const routerKey = el.getAttribute('data-router')
      el.removeAttribute('data-router')
      const router = store.routers.get()[routerKey]

      if (router && router !== this) {
        router.inject(pathName, xhr, requestOptions)
        this.emit('loaded')
        this.cancelTransition()
        return
      }
    }

    const PageClass = this.getPageClass(el)

    const { current } = this.page

    // INTERNAL ROUTING
    if (
      current &&
      current.pageName() === PageClass.pageName &&
      current.internalRouting &&
     (!current.shouldRouteInternally || current.shouldRouteInternally(el, pathName))
    ) {
      this.triggerInternalRouting(pathName, xhr, requestOptions)
      return
    }

    this.pathName = xhr.responseURL
    this.state.next = PageClass.pageName

    if (xhr.responseURL !== pathName && ~xhr.responseURL.indexOf(router.root)) {
      const newPath = xhr.responseURL.replace(router.root, '')
      if (requestOptions.rewriteRoute) requestOptions.rewriteRoute(newPath, false, false)
      else this.rewriteRoute(newPath, false, false)
    }

    this.emit('loaded', page)

    if (this.main) this.updatePageInfo(page)

    this.preparePage(el, PageClass)

    xhr.onload = null
    // this.xhr = null
    return true
  }

  updatePageInfo (page) {
    this.bodyClassName = page.body.className || ''
    this.title.textContent = page.title
  }

  preparePage (el, PageClass) {
    if (this.loadBeforeHide && this.page.current)
      this.page.previous = this.page.current

    const page = this.createPage(el, PageClass)
    this.page.current = page

    const previousPage = this.page.previous && this.page.previous.pageName()

    this.container.appendChild(this.page.current.el)

    return Promise.resolve()
      .then(() => this.page.current?.prepare(previousPage))
      .then(() => {
        this.state.loading = false
        this.state.transitioning = true

        if (this.page.previous) {
          if (this.loadBeforeHide) this.hidePage()
          else if (this.page.previous.state.hidden) this.pageHidden()
          if (this.crossTransition) this.showPage()
        } else {
          this.showPage()
        }
      }).then(() => {
        this.emit('loaded')
      })
  }

  showPage () {
    const previousPage = this.state.previous
    // this.page.current.prepare(previousPage)
    // this.container.appendChild(this.page.current.el)

    if (this.main) document.body.className = this.bodyClassName
    defer(() => this.deferedShowPage(previousPage))
  }

  deferedShowPage (previousPage) {
    router.updatePageLinks()
    this.emit('show', this.page.current)
    this.page.current.askShow(previousPage).then(this.pageShown)
  }

  transitionComplete () {
    if (!this.state.transitioning) return
    const { current: page } = this.page
    this.state.transitioning = false
    this.emit('shown', this.page.current)
    page && page.transitionComplete && page.transitionComplete()
  }

  pageShown = () => {
    if (this.crossTransition && this.page.previous) this.removePage()
    this.transitionComplete()

    if (this.waitingForPage) {
      this.navigateTo(this.waitingForPage)
      this.waitingForPage = null
    }
  }

  hidePage () {
    const nextPage = this.state.next
    this.state.previous = this.page.previous.pageName()
    this.emit('hide', this.page.previous)
    return this.page.previous.askHide(nextPage).then(this.pageHidden)
  }

  pageHidden = () => {
    if (!this.loadBeforeHide && this.state.loading) return

    if (this.crossTransition) {
      if (!this.page.current || this.page.current.state.shown) {
        this.removePage()
        this.transitionComplete()
      }
    } else {
      this.removePage()
      if (this.loadBeforeHide || this.page.current) this.showPage()
    }
  }

  removePage () {
    if (!this.page.previous) return
    this.container.removeChild(this.page.previous.el)
    this.page.previous.flush()
    this.page.previous = null
  }

  getPageClass (el) {
    if (!el) return this.defaultPageClass
    return pageMap()[(el.getAttribute('data-page') || '').trim()] || this.defaultPageClass
  }

  extractPageFromXHR (el) { return this.extractPage(el) }
  extractPage (el) {
    return el.querySelector(this.pageSelector)
  }

  createPage (el, PageClass) {
    const page = new PageClass(el, this.parameters)
    return page
  }

  resize = () => {
    if (this.page.current) this.page.current.resize()
    if (this.page.previous) this.page.previous.resize()
  }

  flush () {
    if (this.page.current) this.page.current.flush()
    if (this.page.previous) this.page.previous.flush()
  }
}

export default PageManager
