import { compact, isArray } from 'lodash'

import Component from 'navigation/component/Component'

class RadioGroup extends Component {
  constructor (el) {
    super(...arguments)
    this.bindRefs()

    this.name = this.refs.radioGroupInputs[0].name

    if (!isArray(this.refs.radioGroupContents))
      this.refs.radioGroupContents = compact([this.refs.radioGroupContents])
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.radioGroupInputs.forEach(input => {
      input[method]('change', this.onToggle)
      if (add) this.onToggle({ currentTarget: input })
    })
  }

  onToggle = (event) => {
    const input = event.currentTarget
    if (!input.checked) return
    this.emit('change', input.value, this.name, input)
    this.value = input.value
    this.refs.radioGroupContents.forEach(block => {
      block.parentNode.classList.toggle('checked', input.value === block.getAttribute('data-id'))
    })
  }

  flush () {
    super.flush()
  }
}

export default RadioGroup
