import { defer, omit } from 'lodash'

import InternalPage from 'navigation/pages/InternalPage'

class Profile extends InternalPage { 
 static pageName = 'Profile'; 
  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const methodNative = add ? 'addEventListener' : 'removeEventListener'

    const select = this.el.querySelector('input[name="change-password"]')
    select && select[methodNative]('change', this.onChange)
  }

  bindModules () {
    super.bindModules()
    this.validations = Object.assign({}, this.modules.form.validations)
    this.updateValidation(false)
  }

  onChange = (event) => {
    this.modules.drawer.el.classList.toggle('opened', event.target.checked)
    this.updateValidation(event.target.checked)
  }

  updateValidation (changePassword) {
    defer(() => {
      let schema = {}

      if (changePassword)
        schema = this.validations
      else
        schema = omit(this.validations, ['new-password', 'password-confirm'])
      this.modules.form.updateSchema(schema)
    })
  }
}

export default Profile
