import { each, zipObject } from 'lodash-es'
import * as yup from 'yup'

import Component from 'navigation/component/Component'
import scroll from 'core/scroll'
import sizeStore from 'store/sizeStore'
import resize from 'helpers/resize'

import validators from './components/validators'

const getFormParameters = (event) => {
  const formData = new FormData(event.currentTarget)

  if (event.submitter && event.submitter.name)
    formData.append(event.submitter.name, event.submitter.value)

  const urlParameters = '?' + [...formData.entries()]
    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&')

  return {
    formData,
    urlParameters
  }
}

class Form extends Component {
  constructor (el, { parent }) {
    super(el)
    this.pageManager = parent.pageManager
    this.preventDefault = true
    this.submitted = false
    this.bindRefs()
    this.parseForm()
    this.quickValid()
  }

  parseForm () {
    this.method = (this.el.getAttribute('method') || 'POST').toUpperCase()
    this.action = this.el.getAttribute('action') || window.location

    this.inputs = [...this.el.querySelectorAll('[name][data-validation], [name][data-required]')]
    this.checkboxes = [...this.el.querySelectorAll('[type="checkbox"][data-validation]')]
    this.inputsByPath = {}

    this.validations = this.inputs.reduce((memo, input) => {
      const required = input.hasAttribute('data-required')
      const validKey = input.getAttribute('data-validation')
      const name = this.formatName(input.name)
      let validator = validators[validKey]
      if (required) validator = validators.required(validator)
      if (validator) memo[name] = validator

      if (!this.inputsByPath[name]) this.inputsByPath[name] = []
      this.inputsByPath[name].push(input)

      return memo
    }, {})
    this.updateSchema(this.validations)
  }

  formatName (name) {
    return name.replace('[', '___').replace(']', '___')
  }

  updateSchema (validations) {
    this.schema = yup.object().shape(validations)
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method]('submit', this.onFormSubmit)

    this.inputs.forEach(input => {
      input[method]('input', this.onInput)
      input[method]('blur', this.onBlur)
    })
  }

  onInput = (event) => {
    this.emit('input', event)
    this.validateField(event.currentTarget.name, false)
    this.quickValid()
  }

  onBlur = (event) => {
    this.validateField(event.currentTarget.name, true)
  }

  validateField (path, addError = false) {
    path = this.formatName(path)

    const inputs = this.inputsByPath[path]
    if (!~this.schema._nodes.indexOf(path)) return
    const values = this.getFormValues()

    this.schema.validateAt(path, values, { context: values })
      .then(() => {
        inputs.forEach(i => {
          i.parentNode.style.setProperty('--form-error', '')
          i.classList.remove('error')
        })
      })
      .catch(({ errors }) => {
        const error = errors[0] || ''
        if (addError) {
          inputs.forEach(i => {
            if (window.dataLayer) {
              const form = i.closest('form[data-name]')
              window.dataLayer.push({
                event: 'error_field',
                field_error_type: error.trim() || 'empty',
                field_name: i.getAttribute('name'),
                form_name: form ? form.getAttribute('data-name') : 'none'
              })
            }
            i.parentNode.style.setProperty('--form-error', this.getError(error))
            i.classList.add('error')
          })
        }
      })
  }

  quickValid () {
    const values = this.getFormValues()
    return this.schema.validate(values, { abortEarly: false, context: values })
      .then((e) => {
        this.el.classList.add('valid')
        this.el.classList.remove('not-valid')
        return true
      })
      .catch(e => {
        this.el.classList.add('not-valid')
        this.el.classList.remove('valid')
        return false
      })
  }

  getFormValues () {
    const formData = new FormData(this.el)
    const keys = [...formData.keys()]
    return zipObject(keys.map(k => this.formatName(k)), keys.map(k => formData.get(k)))
  }

  setSubmitted (submitted = true) {
    this.submitted = submitted
    this.el.classList.toggle('submitted', submitted)
  }

  onFormSubmit = (event) => {
    if (this.submitted) return event.preventDefault()
    if (event.submitter && event.submitter.getAttribute('data-valid') === 'false')
      return this.submit(event)
    if (event.target.hasAttribute('data-direct')) return true

    const values = this.getFormValues()

    try {
      this.schema.validateSync(values, { abortEarly: false, context: values })
      this.setSubmitted(true)
      if (this.preventDefault) {
        event.preventDefault()
        this.submit(event)
      }
    } catch (e) {
      event.preventDefault()
      if (e.errors || e.inner) return this.updateErrors(this.formatError(e))
      throw e
    }
    // .then(() => {
    // })
    // .catch(e => {
    //   if (e.errors || e.inner) return this.updateErrors(this.formatError(e))
    //   throw e
    // })
  }

  formatError (error) {
    const errors = error.inner && error.inner.length ? error.inner : [Object.assign({}, error)]
    return errors.reduce((memo, error) => {
      if (!memo[error.path] && error.errors) memo[error.path] = error.errors[0]
      return memo
    }, {})
  }

  updateErrors (errors) {
    let scrolled = false
    each(this.inputsByPath, (inputs, path) => {
      const error = errors[path]
      inputs.forEach((i, k) => {
        if (!scrolled && !!error) {
          this.scrollTo(i)
          scrolled = true
        }
        i.parentNode.style.setProperty('--form-error', this.getError(error))
        i.classList.toggle('error', !!error)
      })
    })
  }

  scrollTo (el) {
    if (scroll.locked()) return
    const inc = sizeStore.notificationHeight.get() + sizeStore.headerHeight.get()
    const top = el.getBoundingClientRect().top + scroll.scrollTop() - inc
    const s = Math.min(Math.max(0, top), document.scrollingElement.scrollHeight - resize.height())
    scroll.scrollTo(s)
  }

  submit (event) {
    if (this.disabled) return
    const p = getFormParameters(event || { currentTarget: this.el })
    let action = this.action

    if (event && event.submitter && event.submitter.hasAttribute('data-action'))
      action = event.submitter.getAttribute('data-action')

    if (this.method === 'GET') action += p.urlParameters
    // this.el.classList.add('submitting')

    return Promise.resolve()
      .then(() => {
        if (this.submitCallback) return this.submitCallback(p.formData, p.urlParameters)
        // else this.el.classList.remove('submitting')
      })
      .then((prevent) => {
        // this.el.classList.remove('submitting')

        if (this.pageManager.state.loading || prevent === false) return
        this.pageManager.virtual(action, {
          body: p.formData, method: this.method
        })
      })
  }

  hide () {
    this.disabled = true
  }

  setSubmitCallback (cb) {
    this.submitCallback = cb
  }

  getError (errorId) {
    if (!errorId) return ''
    else return JSON.stringify(errorId)
  }

  flush () {
    this.inputs = null
    this.schema = null
    this.checkboxes = null
  }
}

export { getFormParameters }

export default Form
