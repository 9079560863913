
import Component from 'navigation/component/Component'

class DrawerPanel extends Component {
  resize () {
    super.resize()
    this.el.style.setProperty('--drawer-height', this.el.firstElementChild.offsetHeight + 'px')
  }
}

export default DrawerPanel
