import InternalPage from 'navigation/pages/InternalPage'
import store from 'store'

class CheckoutPickupInner extends InternalPage { 
 static pageName = 'CheckoutPickupInner'; 
  constructor (el) {
    super(...arguments)
    this.bindRefs()
  }

  bindEvents (add = true) {
    const m = add ? 'addEventListener' : 'removeEventListener'
    if (this.refs.pickupSelect) this.refs.pickupSelect.forEach(a => a[m]('click', this.onAddressSelected))
  }

  onAddressSelected = ({ currentTarget }) => {
    this.options.parent.pageManager.store.set(null)
    store.checkoutPickup.set(currentTarget.getAttribute('data-id'))
  }
}

export default CheckoutPickupInner
