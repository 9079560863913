import { mapValues } from 'lodash-es'

import variables from 'styles/libs/_variables.scss'

const style = mapValues(Object.assign({}, variables), (v) => {
  if (~v.indexOf('px')) return parseInt(v)
  return v
})

export default style
