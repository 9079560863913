import * as yup from 'yup'

import config from 'core/config'

const codeRegexp = /^[\w]{2,}$/
const phoneRegexp = /^[0-9- _()]+$/
const creditCardRegexp = /^[0-9]{16}$/
const amexRegexp = /^[0-9]{15}$/
const expirationRegexp = /^((0[1-9])|(1[0-2]))\/[0-9]{2}$/
const cvcRegexp = /^[0-9]{3}$/
const cvcAmexRegexp = /^[0-9]{3,4}$/
const nameRegexp = /^[A-z\u00C0-\u00ff\s'\-()]*$/
const latinRegexp = /^[0-9A-z\u00C0-\u00ff\s'.,-/#!$%^&*;:{}=\-_`~()]*$/
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/
const { errors = {} } = config

yup.setLocale({
  mixed: {
    default: errors.invalid,
    typeError: errors.invalid,
    notType: errors.invalid,
    required: errors.required
  },
  number: {
    min: errors.invalid,
    max: errors.invalid
  },
  string: {
    email: errors.email,
    min: errors.invalid,
    max: errors.invalid,
    matches: errors.invalid
  }
})

const validators = {
  address: yup.string().matches(latinRegexp, errors.latin),
  name: yup.string().matches(nameRegexp, errors.latin),
  email: yup.string().email(),
  expirationcard: yup.string().matches(expirationRegexp),
  code: yup.string().matches(codeRegexp),
  password: yup.string().matches(passwordRegexp, errors.password),

  // day: yup.lazy((v) => (v === '' ? yup.string() : )),
  // month: yup.lazy((v) => (v === '' ? yup.string() : yup.number().min(1).max(12))),
  // year: yup.lazy((v) => (v === '' ? yup.string() : yup.number().min(1900).max(2030))),

  city: yup.string().matches(latinRegexp, errors.latin),
  street: yup.string().matches(latinRegexp, errors.latin),
  string: yup.string(),
  phone: yup.string().min(5).matches(phoneRegexp),

  required: (v) => (v || yup.string()).required(),
  bool: (() => {
    const bool = yup.bool()
    bool.required = () => bool.oneOf([true])
    return bool
  })()
}

validators.passwordConfirm = validators.password.oneOf([yup.ref('password'), yup.ref('new-password'), null], errors.confirmPassword)

validators.zipcode = yup.mixed().when(['$country'], {
  is: (country) => country !== 'HK',
  then: yup.string().matches(latinRegexp, errors.latin).required()
})

validators.pickupPhone = yup.mixed().when(['$carrier'], {
  is: (carrier) => carrier === '6',
  then: yup.string().matches(phoneRegexp)
})

validators.creditcard = yup.mixed().when(['$CT'], {
  is: (cardType) => cardType === 'amex',
  then: yup.string().transform(v => v.replace(/ /g, '')).matches(amexRegexp),
  otherwise: yup.string().transform(v => v.replace(/ /g, '')).matches(creditCardRegexp)
})

validators.cvc = yup.mixed().when(['$CT'], {
  is: (cardType) => cardType === 'amex',
  then: yup.string().matches(cvcAmexRegexp),
  otherwise: yup.string().matches(cvcRegexp)
})

validators.day = yup.mixed().when(['$month', '$year'], {
  is: (month, year) => (!!month || !!year),
  then: yup.number().min(1).max(31)
})

validators.month = yup.mixed().when(['$day', '$year'], {
  is: (day, year) => (!!day || !!year),
  then: yup.number().min(1).max(12)
})

validators.year = yup.mixed().when(['$month', '$day'], {
  is: (month, day) => (!!month || !!day),
  then: yup.number().min(1900).max(2030)
})

export default validators
