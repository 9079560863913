
import { defer } from 'lodash-es'

import router from 'core/router'
import store from 'store/store'
import tag from 'core/tag'

import MainPageManager from './MainPageManager'
import CustomPageManager, { extractInfoFromXhr } from './CustomPageManager'

class VirtualPageManager extends CustomPageManager {
  constructor (store, container, pageSelector, defaultPageClass, parameters) {
    super(...Array.from(arguments).slice(1))
    this.main = false
    this.store = store
  }

  updatePageLinks (el) {
    tag.updatePageEvents()

    const links = [].slice.call(el.querySelectorAll('[data-navigo]:not([data-navigo="parent"]), .data-navigo'))
    links.forEach(link => {
      if (link.hasListenerAttached) return
      link.addEventListener('click', this.onLinkClicked)
      link.hasListenerAttached = true
    })
  }

  // Inspired by navigo
  onLinkClicked = (event) => {
    const link = event.currentTarget
    if ((event.ctrlKey || event.metaKey) && event.currentTarget.tagName.toLowerCase() === 'a') return false
    event.preventDefault()
    event.stopImmediatePropagation()
    const location = router.getLinkPath(link)
    this.navigateTo(location.replace(/\/+$/, '').replace(/^\/+/, '/'))
  }

  initializeRoutes () {
    defer(() => {
      this.store.listenAndStart(page => {
        if (this.disabled) return
        this.onRouteUpdate(page)
      })
      const defaultRoute = this.container && this.container.getAttribute('default-route')

      if (!this.page.current && this.extractPage(this.container)) {
        this.store.value = defaultRoute || store.path.get()

        return this.initializePage(this.store.value)
      }
      if (!this.store.value && !this.page.current)
        if (defaultRoute) this.navigateTo(defaultRoute)
    })
  }

  forceRouteUpdate (pathName, xhr, requestOptions, force) {
    this.store.value = pathName
    this.pageLoaded(pathName, xhr, requestOptions, force)
  }

  cancelTransition () {
    this.store.set(null)
    this.state.loading = false
  }

  onRouteUpdate (pathName, requestOptions) {
    if (!pathName && pathName !== '') {
      if (this.page.current) {
        this.page.previous = this.page.current
        this.state.next = this.page.current = null
        this.hidePage()
      }
      return
    }

    super.onRouteUpdate(pathName, requestOptions)
  }

  removePage (page) {
    if (!this.page.previous) return
    if (!this.page.current) this.state.previous = null
    return super.removePage(...arguments)
  }

  load (pathName, options) {
    if (!~pathName.indexOf(router.root)) pathName = router.root + pathName
    return super.load(pathName, options)
  }

  rewriteRoute (pathName, overwrite = true) {
    if (~pathName.indexOf(router.root)) pathName = pathName.replace(router.root, '')
    if (overwrite) {
      this.store.value = pathName
    } else {
      this.disabled = true
      this.store.set(pathName)
      this.disabled = false
    }
  }

  navigateTo (pathName) {
    this.store.set(pathName)
  }

  refresh () {
    const pathName = this.store.value
    this.store.value = ''
    this.store.set(pathName)
  }

  createPage (el, PageClass) {
    const page = MainPageManager.prototype.createPage.call(this, ...arguments)
    this.updatePageLinks(page.el)
    return page
  }

  initializePage () {
    return super.initializePage(...arguments)
  }

  pageLoaded (pathName, xhr, requestOptions) {
    const page = xhr.response
    if (!page || !page.body || !page.body.innerHTML) {
      extractInfoFromXhr(xhr)
      this.state.loading = false
      this.state.transitioning = false
      this.store.set(null)
      return
    }
    return super.pageLoaded(...arguments)
  }

  flush () {
    this.store.unlisten()
    this.store = null
  }
}

export default VirtualPageManager
