import { defer, throttle } from 'lodash-es'

import Component from 'navigation/component/Component'
import store from 'store/store'
import resize from 'helpers/resize'
import mqStore from 'store/mqStore'

import HomeCarousel from './HomeCarousel'
import HomeMedia from './HomeMedia'

class HomeBlocks extends Component {
  getModuleMap () {
    return {
      ...super.getModuleMap(),
      homeCarousel: ['.home-carousel', HomeCarousel],
      homeMedia: ['.home-media', HomeMedia]
    }
  }

  refactorBlocks () {
    const products = Array.from(this.el.querySelectorAll('.home-products'))
    products.forEach(product => {
      const baseItem = ~product.parentNode.className.indexOf('carousel') ? product.parentNode : product

      const clone = baseItem.cloneNode(true)
      const items = Array.from(product.querySelectorAll('.product-card'))
      const cloneItems = Array.from(clone.querySelectorAll('.product-card'))

      const toRemove = [
        ...items.slice(2),
        ...cloneItems.slice(0, 2)
      ]

      toRemove.forEach(item => item.parentNode.removeChild(item))
      baseItem.parentNode.insertBefore(clone, baseItem.nextSibling)
    })
  }

  constructor (el) {
    super(...arguments)
    if (mqStore.tabletPortrait.get()) this.refactorBlocks()
    this.bindModules()

    this.el = el
    this.offsets = []

    this.darkBlocks = Array.from(this.el.children).map(block => {
      if (block.classList.contains('dark-ui') && block.classList.contains('fullscreen')) return true
      if (Array.from(block.firstElementChild?.children).every(slide => {
        return slide.classList.contains('fullscreen') && slide.classList.contains('dark-ui')
      })) return true
      return false
    })

    this.carouselIndexes = []

    this.observer = new MutationObserver(this.scroll)
    this.observer.observe(this.el, { attributes: true, subtree: true })

    defer(() => {
      Array.from(this.el.children).forEach((block, i) => {
        if (!block.classList.contains('home-carousel')) return
        Array.from(block.children).forEach((slide, j) => {
          if (!slide.classList.contains('show')) return
          this.carouselIndexes.push(i)
        })
      })
    })
  }

  bindEvents (add = true) {
    super.bindEvents()
    const method = add ? 'addEventListener' : 'removeEventListener'
    document[method]('scroll', this.scroll)
  }

  show () {
    setTimeout(() => { this.scroll() }, 300)
    return super.show?.(...arguments)
  }

  resize () {
    super.resize()

    this.offsets = Array.from(this.el.children).map((block, index) => {
      if (index === 0) return -100
      return block.offsetTop - .25 * resize.height()
      // return block.offsetTop - .5 * sizeStore.headerHeight.get()
    })
  }

  getOffsetIndex () {
    const scrollTop = document.documentElement.scrollTop
    if (scrollTop < 0) return 0
    if (scrollTop > this.offsets[this.offsets.length - 1]) return this.offsets.length - 1
    return this.offsets.findIndex((offset, index) => {
      if (scrollTop >= offset && scrollTop < this.offsets[index + 1]) return true
      return false
    })
  }

    scroll = throttle((e) => {
      const index = this.getOffsetIndex()

      if (this.carouselIndexes.includes(index)) {
        Array.from(Array.from(this.el.children)[index].children).forEach((slide, i) => {
          if (!slide.classList.contains('show')) return null
          const block = Array.from(slide.children)[0]
          this.darkBlocks[index] = block.classList.contains('dark-ui', 'fullscreen')
        })
      }
      store.darkPage.set(this.darkBlocks[index])
    }, 100)

    flush () {
      super.flush()
      store.darkPage.set(false)
      //  document.scrollingElement.classList.remove('snap');
      if (this.observer) this.observer.disconnect()
    }
}

export default HomeBlocks
