// @todo Remove CheckoutShipping & CheckoutPickup after Omnifull migration
import Category from 'sections/category/Category'
import Cart from 'sections/checkout/Cart'
import Checkout from 'sections/checkout/Checkout'
import CheckoutPayment from 'sections/checkout/CheckoutPayment'
import CheckoutAddress from 'sections/checkout/CheckoutAddress'
import CheckoutPersonalDetails from 'sections/checkout/CheckoutPersonalDetails'
import CheckoutSummary from 'sections/checkout/partials/CheckoutSummary'
import Customer from 'sections/customer/Customer'
import Profile from 'sections/customer/Profile'
import Collection from 'sections/lookbooks/Collection'
import Product from 'sections/product/Product'
import Search from 'sections/search/Search'
import Stores from 'sections/stores/Stores'
import Menu from 'sections/home/Menu'
import CheckoutShipping from 'sections/checkout/CheckoutShipping'
import CheckoutPickup from 'sections/checkout/partials/CheckoutPickup'
import Home from 'sections/home/Home'
import Store from 'sections/stores/Store'
import Article from 'sections/journal/Article'
import Journal from 'sections/journal/Journal'
import SizeGuide from 'sections/size-guide/SizeGuide'

const pageMap = {
  /* Main */
  Category,
  Checkout,
  Product,
  Collection,
  Stores,
  Store,
  Home,
  Article,
  Journal,

  /* Panel */
  Cart,
  Menu,

  /* Search */
  Search,

  /* Checkout */
  CheckoutPersonalDetails,
  CheckoutShipping,
  CheckoutSummary,
  CheckoutPayment,
  CheckoutPickup,
  CheckoutAddress,

  /* Customer */
  Customer,
  Profile,

  /* Popin */
  SizeGuide
}

export default pageMap
