const shortestAngle = (from, to, lower, upper) => {
  const difference = from - to
  const halfDistance = (upper - lower) / 2
  return wrap(difference + halfDistance, lower, upper) - halfDistance
}

const wrap = (value, lower, upper) => {
  const distance = upper - lower
  const times = Math.floor((value - lower) / distance)
  return value - times * distance
}

const round = (number, precision) => Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)

const even = (number) => Math.ceil(number / 2) * 2

const scale = (method, containerW, containerH, contentW, contentH, safeZone = null) => {
  const scaleX = containerW / contentW
  const scaleY = containerH / contentH
  let scaleXSafe, scaleYSafe

  if (safeZone) {
    scaleXSafe = containerW / (contentW * safeZone)
    scaleYSafe = containerH / (contentH * safeZone)
  }

  switch (method) {
    case 'contain':
      return Math.min(scaleX, scaleY)

    case 'width':
      return scaleX

    case 'height':
      return safeZone
        ? Math.max(Math.min(scaleX, scaleYSafe), scaleY)
        : scaleY

    case 'cover':
      return safeZone
        ? Math.max(
          Math.min(scaleX, scaleYSafe),
          Math.min(scaleY, scaleXSafe)
        )
        : Math.max(scaleX, scaleY)
  }
}

const clamp = (value, min, max) => Math.min(max, Math.max(min, value))
const map = (value, start1, stop1, start2, stop2) => start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1))
const mapClamp = (value, start1, stop1, start2 = 0, stop2 = 1) => clamp(map(value, start1, stop1, start2, stop2), start2, stop2)

export default {
  shortestAngle,
  wrap,
  round,
  even,
  scale,
  clamp,
  map,
  mapClamp
}
