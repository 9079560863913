
import Component from 'navigation/component/Component'

class CheckoutFooter extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    if (this.refs.checkoutFooterToggles) this.refs.checkoutFooterToggles.forEach(button => button[method]('click', this.onToggle))
  }

  onToggle = (event) => {
    event && event.preventDefault()
    const { currentTarget } = event
    const clickedSibling = currentTarget.nextElementSibling

    const opened = !clickedSibling.classList.contains('opened')

    if (!opened) {
      currentTarget.classList.remove('current')
      clickedSibling.classList.remove('opened')
    } else {
      this.refs.checkoutFooterToggles.forEach((button, i) => {
        const current = button === currentTarget
        button.classList.toggle('current', current)
        button.nextElementSibling.classList.toggle('opened', current)
      })
    }
  }
}

export default CheckoutFooter
