import anime from 'animejs'

import config from 'core/config'
import resize from 'helpers/resize'
import Component from 'navigation/component/Component'
import { startPanels } from 'store/middlewares/panels'
import sizeStore from 'store/sizeStore'
import store from 'store/store'

class Header extends Component {
  constructor (el) {
    super(...arguments)
    this.bindRefs()
    this.resize()
  }

  bindEvents () {
    store.search.listenAndStart(this.onSearchUpdated)
    store.menu.listenAndStart(this.onMenuUpdated)
    store.cart.listen(this.onCartUpdate)
    store.logged.listen(this.onLoginStatusUpdate)
    store.alternateLinks.listen(this.onAlternateLinkUpdate)
    this.refs.headerMenuButton.addEventListener('click', this.onMenuClicked)
    this.bindInternalRouter()
  }

  show () {
    const from = (resize.height() / 2) - (sizeStore.notificationHeight.get() + sizeStore.headerHeight.get() / 2)
    const links = this.el.querySelectorAll('.header__link,.header__menu')
    const timeline = anime.timeline({})

    timeline.add({
      targets: this.refs.headerLogo,
      translateY: {
        value: [from, 0],
        easing: 'easeInOutCubic',
        delay: 800,
        duration: 1200
      },
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: 700
      }
    }, 0)

    timeline.add({
      targets: links,
      duration: 500,
      translateY: {
        value: [10, 0],
        easing: 'easeOutCubic'
      },
      opacity: {
        value: [0, 1],
        easing: 'linear'
      },
      delay: 1000
    }, 0)

    return timeline.finished
      .then(() => {
        links.forEach(l => {
          l.removeAttribute('style')
        })

        this.refs.headerLogo.style.transform = ''
        this.el.classList.add('shown')
        startPanels()
      })
  }

  bindRefs () {
    super.bindRefs()
  }

  onCartUpdate = (count) => {
    this.refs.headerCart.setAttribute('data-count', count)
    this.refs.headerCart.innerText = count
  }

  onAlternateLinkUpdate = (links = {}) => {
    if (!this.refs.headerAlternateLinks) return
    this.refs.headerAlternateLinks.forEach(link => {
      const lang = link.getAttribute('data-lang')
      const href = links[lang]
      if (href) link.setAttribute('href', href)
      else link.setAttribute('href', config.baseurl + '/' + lang)
    })
  }

  onLoginStatusUpdate = (logged) => {
    this.el.classList.toggle('logged', logged)
  }

  onMenuClicked =(e) => {
    if (store.menu.get() === null) return
    event.stopImmediatePropagation()
    store.menu.set(null)
  }

  onMenuUpdated = (menu) => {
    const label = this.refs.headerMenuButton.getAttribute('data-label').split('|')
    this.refs.headerMenuButton.innerText = label[menu ? 1 : 0]

    this.refs.headerMenuButton.classList.toggle('opened', menu)
  }

  onSearchUpdated = (search) => {
    this.el.classList.toggle('with-search', search)
  }

  resize () {
    const height = this.el.offsetHeight
    sizeStore.headerHeight.set(height)
  }
}

export default Header
