
import MainPage from './MainPage'

export default class InternalPage extends MainPage { 
 static pageName = 'InternalPage'; 
  constructor (el) {
    super(...arguments)
    el.classList.add('internal-page')
  }

  /* SHOW */
  prepare (previousPage) {
    if (previousPage) this.el.style.opacity = 0
    return super.prepare(...arguments)
  }

  preload (previousPage) {
    if (previousPage) return super.preload(...arguments)
    else return Promise.resolve()
  }

  getChildrenShowDelay () {
    const { previousMainPage, previousPage } = this.options
    return super.getChildrenShowDelay() + ((!previousPage && !previousMainPage) ? 800 : 0)
  }

  getTranslateY (hide) {
    return hide ? '-40vh' : '40vh'
  }

  show (previousPage) {
    if (!previousPage) {
      this.el.style.opacity = 1
      this.showChildren()
      return Promise.resolve()
    } else {
      return super.show(previousPage)
    }
  }
}
