import { invokeMap } from 'lodash'

import moduleMap from 'core/moduleMap'

import Component from '../component/Component'

class BasePage extends Component {
  state = { shown: false, hidden: false }

  pageName () { return this.constructor.pageName || this.constructor.name || true }

  constructor (el, { pageManager } = {}) {
    super(...arguments)
    this.pageManager = pageManager
    this.bindRefs()
  }

  deferredInit () {}

  bindModules () {
    super.bindModules(...arguments)
    this.bindEvents(true)
  }

  getModuleMap () {
    return Object.assign({}, moduleMap)
  }

  /* PREPARE */

  prepare (previousPage) {
    if (previousPage) this.injectScripts()
    this.el.classList.add('preparing')

    return Promise.resolve()
      .then(() => this.bindInternalRouter())
      .then(() => this.preload(previousPage))
  }

  injectScripts () {
    const scripts = [...this.el.querySelectorAll('script[data-inject]')]
    scripts.forEach(script => {
      if (!script.src) {
        const s2 = document.createElement('script')
        s2.type = 'text/javascript'
        if (script.innerHTML) s2.appendChild(document.createTextNode(script.innerHTML))
        script.parentNode.replaceChild(s2, script)
      }
    })
  }

  /* PRELOAD */

  preload (previousPage) {
    return this.preloadImages()
  }

  /* LOAD IMAGES */

  preloadImages () {
    const imagesToLoad = this.getImagesToLoad()
    if (!imagesToLoad || !imagesToLoad.length) return Promise.resolve()
    return Promise.all(imagesToLoad.map((v, k) => this.loadImage(v, k, imagesToLoad.length)))
  }

  getImagesToLoad () {
    return Array.from(this.el.querySelectorAll('img:not([loading=lazy])[src]'))
  }

  loadImage (img, i, total) {
    if (~img.src.indexOf('svg')) return Promise.resolve()

    return new Promise(resolve => {
      if (img.naturalWidth !== 0 || img.complete) return resolve()

      img.onerror = img.onload = () => {
        img.onerror = img.onload = null
        resolve()
      }
      img.src = img.src // eslint-disable-line
    })
  }

  /* SHOW */

  askShow = previousPage => {
    this.options.previousPage = previousPage
    this.el.classList.remove('preparing')
    return Promise.resolve()
      .then(() => this.bindModules(previousPage))
      .then(() => this.show(previousPage))
      .then(() => { this.state.shown = true })
  }

  show (previousPage) {
    this.el.style.opacity = 1
    this.invokeShow()
  }

  invokeShow () {
    invokeMap(this.modulesArray, 'show')
  }

  invokeHide () {
    invokeMap(this.modulesArray, 'hide')
  }

  transitionComplete () {
    invokeMap(this.modulesArray, 'transitionComplete')
  }

  /* HIDE */

  askHide = nextPage => {
    return Promise.resolve()
      .then(() => this.invokeHide())
      .then(() => this.hide(nextPage))
      .then(() => { this.state.hidden = true })
  }

  hide (nextPage) {
    this.el.style.opacity = 0
  }

  /* RESIZE */

  resize () {
    invokeMap(this.modulesArray, 'resize')
  }

  /* FLUSH */

  flush () {
    invokeMap(this.modulesArray, 'flush')
  }
}

export default BasePage
