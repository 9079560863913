import PanelPage from 'navigation/pages/PanelPage'
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import Component from 'navigation/component/Component'
import store from 'store/store'
import scroll from 'core/scroll'

class Panel extends Component {
  constructor (el) {
    super(...arguments)
    this.bindRefs()
    this.pageManager = new VirtualPageManager(store.panel, el.querySelector('.panel__inner'), '.page', PanelPage)
    store.routers.get().panel = this.pageManager
  }

  bindEvents () {
    store.panel.listenAndStart(this.onPanelUpdate)
    this.refs.closePanel.addEventListener('click', this.onClose)
    this.refs.overlayPanel.addEventListener('click', this.onClose)
    this.pageManager.on('loading', this.onLoading)
    this.pageManager.on('loaded', this.onLoaded)
  }

  onLoading =() => {
    this.el.classList.add('loading')
  }

  onLoaded =() => {
    this.el.classList.remove('loading')
  }

  onClose = () => {
    store.panel.set(null)
  }

  resize () {
    this.pageManager.resize()
    super.resize()
  }

  onPanelUpdate = (panel) => {
    const notEmpty = !!panel || panel === ''
    this.el.classList.toggle('opened', notEmpty)
    if (notEmpty) scroll.lock(true)
    else scroll.unlock(true)
  }
}

export default Panel
