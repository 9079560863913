
import { findLastIndex, throttle } from 'lodash'

import Component from 'navigation/component/Component'
import Carousel from 'components/carousel/Carousel'
import sizeStore from 'store/sizeStore'
import scroll from 'core/scroll'
import mqStore from 'store/mqStore'
import resize from 'helpers/resize'

class ProductGallery extends Component {
  getModuleMap = () => ({
    carousel: ['.product-gallery__list', Carousel]
  })

  constructor () {
    super(...arguments)
    this.bindModules()
    this.bindRefs()
  }

  bindEvents (add = true) {
    const m = add ? 'addEventListener' : 'removeEventListener'
    scroll[add ? 'on' : 'off'](this.onScroll)
    mqStore.tabletPortrait[add ? 'listenAndStart' : 'unlisten'](this.onMqUpdate)
    this.modules.carousel[add ? 'on' : 'off']('click', this.onCarouselClick)
    this.refs.zoomItem.forEach((item, index) => item[m]('click', this.onOpenZoom(index)))
  }

  onCarouselClick = () => {
    let inc = 0
    const index = this.modules.carousel.step.current
    this.refs.galleryItems.forEach((item, i) => {
      if (i === index) {
        this.options.parent.modules.zoom.open(inc)
        return false
      }
      if (~item.getAttribute('data-refs').indexOf('zoomItem')) inc++
    })
  }

  onOpenZoom = index => () => {
    this.options.parent.modules.zoom.open(index)
  }

  onMqUpdate = () => {
    if (mqStore.tabletPortrait.get()) this.modules.carousel.enable()
    else this.modules.carousel.disable()
  }

  onScroll = throttle(() => {
    if (mqStore.tabletPortrait.get()) return
    const scrollTop = scroll.scrollTop()

    let index = findLastIndex(this.bounds, (top) => scrollTop > top)
    index = Math.max(1, index + 1)
    if (index !== this.index) {
      this.refs.galleryCount.innerText = index
      this.index = index
    }

    const hidden = scrollTop > this.bottom
    if (hidden !== this.hidden) {
      this.el.classList.toggle('hide-counter', hidden)
      this.hidden = hidden
    }
  }, 100)

  resize () {
    super.resize()
    const inc = sizeStore.notificationHeight.get() + sizeStore.headerHeight.get()
    this.bounds = this.refs.galleryItems.map(item => inc + item.offsetTop + (item.offsetHeight / 2))
    this.bounds.pop()
    this.bounds.unshift(0)

    this.bottom = this.el.getBoundingClientRect().bottom + scroll.scrollTop() - resize.height()
  }
}

export default ProductGallery
