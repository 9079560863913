
import SwitchButton from 'components/switch-button/SwitchButton'
import { staggerItems } from 'core/animations'
import router from 'core/router'

import InternalRouter from './InternalRouter'

class TransversalPage extends InternalRouter { 
 static pageName = 'TransversalPage'; 
  constructor () {
    super(...arguments)
    const button = this.el.querySelector('.transversal__button')
    if (button) {
      const option = document.createElement('option')
      option.value = button.textContent + '|' + button.href
      option.textContent = button.textContent
      if (this.refs.transversalSelect) this.refs.transversalSelect.appendChild(option)
    }
  }

  bindRefs () {
    super.bindRefs()
  }

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const method = add ? 'on' : 'off'
    const methodNative = add ? 'addEventListener' : 'removeEventListener'
    this.modules.content[method]('show', this.updateContent)
    this.refs.transversalSelect[methodNative]('change', this.onSelect)
  }

  onSelect = ({ target }) => {
    const [key, link] = target.value.split('|') // eslint-disable-line
    router.navigate(link.replace(router.root, ''))
    this.refs.transversalSubtitle.innerText = target.options[target.selectedIndex].innerText
  }

  getModuleMap () {
    return {
      ...super.getModuleMap(),
      switchButton: ['.switch-button', SwitchButton]
    }
  }

  invokeShow () {
    staggerItems(this.refs.transversalLinks, { initialDelay: 1000 })
    return super.invokeShow(...arguments)
  }

  updateContent = (page) => {
    const id = page.el.getAttribute('data-id').trim()
    const title = page.el.getAttribute('data-title').trim()

    this.refs.transversalTitle.innerText = title
    this.refs.transversalLinks.forEach(link => {
      const isCurrent = id === link.getAttribute('data-id')
      link.classList.toggle('current', isCurrent)
    })

    Array.from(this.refs.transversalSelect.options).forEach(option => {
      option.selected = option.value.split('|')[0] === id
      if (option.selected) this.refs.transversalSubtitle.innerText = option.innerText
    })
  }
}

export default TransversalPage
