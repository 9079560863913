import DrawerPanel from 'components/drawer-panel/DrawerPanel'
import Form from 'components/form/Form'
import Component from 'navigation/component/Component'
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager'

class Footer extends Component {
  constructor () {
    super(...arguments)
    this.pageManager = this.options.parent.pageManager
  }

  deferredInit () {
    this.bindRefs()
    this.bindModules()
    super.deferredInit()
  }

  bindEvents (add = true) {
    const m = add ? 'addEventListener' : 'removeEventListener'
    this.refs.footerAccordion.forEach(a => a[m]('click', this.onAccordionClick))
  }

  bindModules () {
    super.bindModules()
    this.modules.newsletterForm.submitCallback = this.onFormSubmit
  }

  onFormSubmit = (formData, urlParameters) => {
    const form = this.modules.newsletterForm
    const xhr = new XMLHttpRequest()
    this.loading = true

    xhr.withCredentials = true
    xhr.open(form.method, form.action, true)
    xhr.responseType = 'document'
    xhr.onload = this.onXhrComplete
    xhr.send(formData)
    return false
  }

  onXhrComplete = (event) => {
    const form = this.modules.newsletterForm
    form.submitted = false

    this.loading = false
    const xhr = event.currentTarget
    extractInfoFromXhr(xhr)
    xhr.onload = null
  }

  onAccordionClick = ({ currentTarget }) => {
    currentTarget.parentNode.parentNode.classList.toggle('opened')
  }

  getModuleMap () {
    return {
      newsletterForm: ['.footer__form', Form],
      footerAccordion: ['.footer__accordion', DrawerPanel]
    }
  }
}

export default Footer
