import { each } from 'lodash'

import mqSizes from 'styles/libs/_mq.scss'

// import text from '../helpers/text'
import resize from '../helpers/resize'
import { createStore } from '../helpers/state'

// const prefix = 'mq'
const initialState = {}
const match = {}

each(mqSizes, (size, k) => {
  const key = k.replace('MaxWidth', '')
  size = parseInt(size)
  initialState[key] = resize.width() < size
  match[key] = size
})

const mqStore = createStore(initialState)

resize.add({
  resize: () => {
    each(mqStore, (state, key) => {
      state.set(resize.width() < match[key])
    })
  }
})

export default mqStore
