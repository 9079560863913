
import { throttle } from 'lodash-es'

import MainPage from 'navigation/pages/MainPage'
import scroll from 'core/scroll'
import router from 'core/router'
import store from 'store'
import resize from 'helpers/resize'
import browser from 'helpers/browser'
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager'
import RevealList from 'components/reveal-list/RevealList'

import ArticleGallery from './partials/ArticleGallery'

class Journal extends MainPage { 
 static pageName = 'Journal'; 
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.parsePages()
  }

  bindEvents (add = true) {
    scroll[add ? 'on' : 'off'](this.onScroll)
  }

  getModuleMap () {
    return {
      ...super.getModuleMap(),
      gallery: ['.article-gallery', ArticleGallery],
      revealList: ['.journal__inner', RevealList]
    }
  }

  parsePages () {
    this.currentPage = +this.refs.journalList.getAttribute('data-page')
    this.nbPages = +this.refs.journalList.getAttribute('data-nb-pages')
    this.endpoint = this.refs.journalList.getAttribute('data-src')
  }

  preload (previousPage) {
    return super.preload(previousPage).then(() => {
      if (!store?.lastProduct.get()) return
      const { page } = store.lastProduct.get()
      if (+page === 1) return
      this.preloadingPages = true

      return Array(page - 1).fill(1).reduce((promise, _, i) => {
        return promise.then(() => this.loadNewPage())
      }, Promise.resolve()).then(() => {
        if (!this.modules) this.bindModules()
        this.modules.revealList.reset()
        this.bindRefs()
        router.updatePageLinks()
        this.resize()
      })
    })
  }

  loadNewPage () {
    if (this.loading || this.currentPage === this.nbPages) return
    this.loading = true

    this.currentPage++

    const link = this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + this.currentPage

    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true
      xhr.responseType = 'document'
      xhr.open('GET', link, true)
      xhr.setRequestHeader('X-Fursac-Async', 'true')
      xhr.onload = (event) => {
        this.onPageLoaded(event)
        resolve()
      }
      xhr.send()
    })
  }

  onScroll = throttle(() => {
    if (this.bottomList < scroll.scrollTop()) this.loadNewPage()
  }, 1000)

  onPageLoaded = ({ currentTarget: xhr }) => {
    this.loading = false
    const page = xhr.response
    extractInfoFromXhr(xhr)
    xhr.onload = null

    const items = Array.from(page.querySelectorAll('.journal-card'))
    items.forEach(item => {
      const image = item.querySelector('.journal-card__img img')
      this.setOrientation(image)
      this.refs.journalList.appendChild(item)
    })
    if (this.preloadingPages) return
    this.modules.revealList.reset()

    router.updatePageLinks()
    browser.waitRepaint(() => {
      this.bindEvents(false)
      this.bindRefs() // a verifier que ça bind bien
      this.bindEvents(true)
      this.resize()
    })
  }

  resize () {
    super.resize()
    const offset = resize.height() * 3
    const bottom = browser.offsetTop(this.refs.journalList) + this.refs.journalList.offsetHeight
    this.bottomList = bottom - resize.height() - offset
  }

  show () {
    this.detectOrientations()
    super.show(...arguments)
  }

  detectOrientations () {
    if (this.refs.images) {
      this.refs.images.forEach(img => {
        if (img.naturalWidth) this.setOrientation(img)
        else img.onload = () => this.setOrientation(img)
      })
    }
  }

  setOrientation (img) {
    img.onload = null
    const orientation = img.naturalWidth / img.naturalHeight > 1 ? 'landscape' : 'portrait'
    img.parentNode.classList.add(orientation)
  }
}

export default Journal
