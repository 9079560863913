import scroll from 'core/scroll'
import SuperPageManager from 'navigation/page-manager/MainPageManager'
import InternalRouter from 'navigation/pages/InternalRouter'
import MainPage from 'navigation/pages/MainPage'
import store from 'store'
import sizeStore from 'store/sizeStore'

import CategoryProducts from './partials/CategoryProducts'

class Category extends InternalRouter { 
 static pageName = 'Category'; 
  constructor () {
    super(...arguments)
    this.bindRefs()
  }

  show () {
    return super.show(...arguments).then(() => {
      if (!store.lastProduct.get()) return

      const lastProduct = this.el.querySelector('.product-card[data-id="' + store.lastProduct.get().id + '"]')

      if (lastProduct && store.lastProduct.get().src === this.refs.categoryList.getAttribute('data-src')) {
        store.lastProduct.set(null)
        setTimeout(() => {
          scroll.scrollTo(lastProduct.parentNode.offsetTop)
        }, 100)
      }
    })
  }

  bindModules () {
    super.bindModules(...arguments)
  }

  prepare (previousPage) {
    if (previousPage !== 'Product') store.lastProduct.set(null)
    return super.prepare(...arguments)
  }

  preload () {
    this.bindModules()
    const { content } = this.modules

    return new Promise(resolve => {
      if (!content.state.init) content.once('init', resolve)
      else resolve()
    })
  }

  getModuleMap () {
    const map = MainPage.prototype.getModuleMap.call(this)
    delete map.revealList
    delete map.maskReveal
    return map
  }

  getPageSelector () { return '.category__inner' }

  getDefaultPageClass () { return CategoryProducts }

  bindEvents (add = true) {
    const method = add ? 'on' : 'off'
    if (this.modules.content) this.modules.content[method]('shown', this.onPageUpdate)
  }

  onPageUpdate = (page) => {
    SuperPageManager.prototype.resetScroll.call(this)
  }

  shouldRouteInternally (el) {
    // add offset
    const a = el.querySelector('.category__list')
    if (!a) return false

    const linkA = (a.getAttribute('data-src') || '').split('?')[0]
    const linkB = (this.modules.content.page.current.el.querySelector('.category__list').getAttribute('data-src') || '').split('?')[0]
    return linkA === linkB
  }

  internalRouting (pathName, xhr, requestOptions = {}) {
    const el = xhr.response

    // Update bar count
    const c = el.querySelector('.catalog-bar__count')
    if (c) this.refs.barCount.textContent = c.textContent

    // Update top for transition
    const a = el.querySelector('.category__inner')

    if (a) {
      const offset = Math.min(document.scrollingElement.scrollTop, sizeStore.notificationHeight.get())
      const top = scroll.scrollTop() - offset
      a.style.top = top + 'px'
    }

    return super.internalRouting(...arguments)
  }

  scrollToTop (cb) { cb() }
}

export default Category
