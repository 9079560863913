
import MainPage from 'navigation/pages/MainPage'

import CollectionPopin from './partials/CollectionPopin'

class Collection extends MainPage { 
 static pageName = 'Collection'; 
  constructor () {
    super(...arguments)
    this.bindRefs()
  }

  getModuleMap () {
    return {
      popin: ['.collection-popin', CollectionPopin],
      ...super.getModuleMap()
    }
  }

  bindEvents (add = true) {
    super.bindEvents()
    const methodNative = add ? 'addEventListener' : 'removeEventListener'
    this.refs.lookItems && this.refs.lookItems.forEach((item) => item[methodNative]('click', this.openPopin))
  }

  openPopin = ({ currentTarget }) => {
    const id = +currentTarget.getAttribute('data-id')
    this.modules.popin.open(id)
  }

  hide = nextPage => {
    return Promise.resolve()
      .then(() => {
        if (this.modules.popin.opened) {
          this.refs.pageInner.style.display = 'none'
          this.modules.footer.el.style.display = 'none'
          return this.modules.popin.close()
        } else {
          return super.hide(nextPage)
        }
      })
  }
}

export default Collection
