import ButtonRect from 'components/button-rect/ButtonRect'
import CategoriesList from 'components/categories-list/CategoriesList'
import RadioGroup from 'components/radio-group/RadioGroup'
import DrawerPanel from 'components/drawer-panel/DrawerPanel'
import Form from 'components/form/Form'
import AddressForm from 'components/form/AddressForm'
import SocialLogin from 'components/social-login/SocialLogin'
import PasswordField from 'components/password-field/PasswordField'
import ScrollIndicator from 'components/scroll-indicator/ScrollIndicator'
import FilterBar from 'components/filter-bar/FilterBar'
import Player from 'components/player/Player'
import Footer from 'components/footer/Footer'
import RevealList from 'components/reveal-list/RevealList'
import MaskReveal from 'components/maskReveal/MaskReveal'
import ParallaxImage from 'components/parallax-image/ParallaxImage'
import Reveal from 'components/reveal/Reveal'
import SwitchButton from 'components/switch-button/SwitchButton'
import ToggleWebstoreSwitcher from 'components/toggle-webstore-switcher/ToggleWebstoreSwitcher'

const moduleMap = {
  scrollIndicator: ['.scroll-indicator', ScrollIndicator],
  parallaxImage: ['.parallax-image', ParallaxImage],
  form: ['.form', Form],
  footer: ['.footer', Footer],
  filterBar: ['.filter-bar', FilterBar],
  passwordField: ['.field input[type="password"]', PasswordField],
  socialLogin: ['.social-login', SocialLogin],
  switchButton: ['.switch-button', SwitchButton],
  addressForm: ['.address-form', AddressForm],
  player: ['.player', Player],
  drawer: ['.drawer-panel', DrawerPanel],
  radioGroup: ['.radio-group', RadioGroup],
  categoriesList: ['.categories-list', CategoriesList],
  revealList: ['.reveal-list', RevealList],
  toggleWebstoreSwitcher: ['[data-toggle=wss]', ToggleWebstoreSwitcher],
  reveal: ['.reveal', Reveal],
  buttonRect: ['.button-rect, .button-rect-light, .button-rect-grey', ButtonRect],
  maskReveal: ['.mask-reveal', MaskReveal]
}

export default moduleMap
