
import anime from 'animejs'

import browser from 'helpers/browser'
import Component from 'navigation/component/Component'

class ProductInfos extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.opened = false
    this.targetHeight = 0
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    if (this.refs.productInfoToggle) this.refs.productInfoToggle.forEach(button => button[method]('click', this.onClickMobileInfo))
    if (this.refs.productInfoButtons) this.refs.productInfoButtons.forEach(button => button[method]('click', this.onClickInfo))
  }

  /* INFO */

  onClickInfo = event => {
    event && event.preventDefault()
    const index = Array.prototype.indexOf.call(event.currentTarget.parentNode.children, event.currentTarget)
    this.open(this.refs.productInfoToggle[index])
  }

  onClickMobileInfo = event => {
    event && event.preventDefault()
    this.open(event.currentTarget)
  }

  open (clicked) {
    const parent = clicked.parentNode
    const index = Array.prototype.indexOf.call(parent.parentNode.children, parent)
    const clickedSibling = clicked.nextElementSibling

    const opened = !clickedSibling.classList.contains('opened')

    if (!opened) {
      clickedSibling.classList.remove('opened')
      this.refs.productInfoButtons[index].classList.remove('current')
      this.resizeTarget = null
    } else {
      this.resizeTarget = this.refs.productInfoItem[index]
      this.refs.productInfoList.style.transform = `translateX(${-index * 100}%)`
      this.refs.productInfoToggle.forEach((button, i) => {
        button.nextElementSibling.classList.toggle('opened', i === index)
        this.refs.productInfoButtons[i].classList.toggle('current', i === index)
      })
    }

    this.setHeight(opened ? this.resizeTarget.offsetHeight : 0)

    if (this.opened !== opened) {
      browser.waitRepaint(() => {
        this.el.classList.toggle('opened', opened)
      })
      this.opened = opened
    }
  }

  setHeight (height, animate = true) {
    if (animate) {
      return anime({
        targets: this.refs.productInfoList.parentNode,
        height,
        easing: 'easeInOutCubic',
        round: 1,
        duration: 500
      }).finished
    } else {
      this.refs.productInfoList.parentNode.style.height = height + 'px'
    }
  }

  onCloseInfo = event => {
    event && event.preventDefault()

    this.refs.productInfoToggle.forEach(button => {
      button.nextElementSibling.classList.remove('opened')
    })
  }

  resize () {
    super.resize()
    if (this.resizeTarget) this.setHeight(this.resizeTarget.offsetHeight, false)
  }
}

export default ProductInfos
