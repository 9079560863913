
import Component from 'navigation/component/Component'

class ArticleImage extends Component {
  deferredInit () {
    super.deferredInit()
    if (this.el.naturalWidth) this.setOrientation(this.el)
    else this.el.onload = () => this.setOrientation(this.el)
  }

  setOrientation (img) {
    img.onload = null
    const orientation = img.naturalWidth / img.naturalHeight > 1 ? 'landscape' : 'portrait'
    img.classList.add(orientation)
  }
}

export default ArticleImage
