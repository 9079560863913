import SearchPage from 'navigation/pages/SearchPage'

class Search extends SearchPage { 
 static pageName = 'Search'; 
  show () {
    const input = this.el.querySelector('input[type="text"]')
    input.focus()
    return super.show()
  }
}

export default Search
