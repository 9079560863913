
import Component from 'navigation/component/Component'
import scroll from 'core/scroll'
import resize from 'helpers/resize'

class ProductBar extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
  }

  getDetails () {
    return this.options.parent.modules.details
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    const method2 = add ? 'once' : 'off'
    this.refs.productBarAdd[method]('click', this.onAddProduct)
    this.refs.productBarFav[method]('click', this.onFavProduct)
    this.getDetails()[method2]('valid', this.onValid)
  }

  onValid = () => {
    this.el.classList.remove('not-valid')
    this.el.classList.add('valid')
  }

  onAddProduct = event => {
    this.getDetails().modules.form.quickValid()
      .then((valid) => {
        if (valid) this.getDetails().addProduct()
        else this.scrollTop()
      })
  }

  onFavProduct = event => {
    this.getDetails().modules.form.quickValid()
      .then((valid) => {
        if (valid) this.getDetails().favProduct()
        else this.scrollTop()
      })
  }

  scrollTop = () => {
    const top = this.getDetails().el.getBoundingClientRect().bottom - resize.height() + scroll.scrollTop()
    scroll.scrollTo(top, { duration: 400 })
  }
}

export default ProductBar
