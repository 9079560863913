
import anime from 'animejs'

import scroll from 'core/scroll'
import text from 'helpers/text'
import Component from 'navigation/component/Component'
import browser from 'helpers/browser'
import SwipeCarousel from 'components/carousel/SwipeCarousel'
import detect from 'helpers/detect'

class CollectionPopin extends Component {
  deferredInit () {
    this.bindRefs()
    this.bindModules()
    super.deferredInit()
  }

  getModuleMap () {
    return {
      carousel: ['.collection-popin__inner', SwipeCarousel]
    }
  }

  open (id = 0) {
    this.el.style.display = 'block'

    browser.waitRepaint(() => {
      this.el.classList.add('opened')
      document.documentElement.classList.add('no-header')
      this.modules.carousel.enable()
      this.modules.carousel.enableKeyboard && this.modules.carousel.enableKeyboard()
      this.modules.carousel.goTo(id)
      this.onUpdate(id)
    })

    if (!this.init) {
      Array.from(this.el.querySelectorAll('img[data-src]')).forEach(i => {
        i.src = i.getAttribute('data-src')
      })
      this.init = true
    }

    return anime({
      targets: this.refs.wrapperPopin,
      // translateY: ['-100%', 0],
      opacity: [0, 1],
      easing: 'easeInOutCubic',
      duration: 500
    }).finished.then(() => {
      this.opened = true
      scroll.lock(true)
    })
  }

  onClose = () => {
    this.close()
  }

  close () {
    this.opened = false
    document.documentElement.classList.remove('no-header')
    this.el.classList.remove('opened')
    this.modules.carousel.disable()
    scroll.unlock(true)
    const wrapperPopin = this.refs.wrapperPopin

    return anime({
      targets: wrapperPopin,
      opacity: [1, 0],
      easing: 'easeInOutCubic',
      duration: 500,
      complete: () => {
        this.el.style.display = 'none'
      }
    }).finished
  }

  bindEvents (add = true) {
    super.bindEvents()
    const method = add ? 'on' : 'off'
    const methodNative = add ? 'addEventListener' : 'removeEventListener'
    document[methodNative]('keydown', this.escape)
    if (this.refs.closePopin) this.refs.closePopin[methodNative]('click', this.onClose)
    if (this.refs.next) this.refs.next[methodNative]('click', this.onNext)
    if (this.refs.previous) this.refs.previous[methodNative]('click', this.onPrevious)
    this.modules.carousel[method]('update', this.onUpdate)
    this.onUpdate(0)
  }

  escape = event => {
    if (this.opened && event.key === 'Escape')
      this.close()
  }

  onUpdate = (step, forward) => {
    // if (!this.opened) return
    if (!this.refs.relatedProducts || !this.refs.relatedProducts.length) return

    const next = this.refs.relatedProducts[step]
    const previous = this.current

    if (next === previous || !next) return

    if (previous) {
      anime({
        targets: previous,
        translateX: forward ? '-30%' : '30%',
        opacity: 0,
        easing: 'easeOutCubic',
        duration: 500,
        complete: () => {
          previous.style.display = 'none'
        }
      })

      const index = Array.from(previous.parentNode.children).indexOf(previous)
      const prevItem = this.el.querySelectorAll('.collection-popin__item')[index]
      scroll.scrollTo(0, { target: prevItem })
    }

    next.style.display = 'block'
    browser.waitRepaint(() => {
      const height = next.offsetHeight
      this.el.style.setProperty('--product-height', height + 'px')
    })

    const initDelay = detect.desktop ? 200 : 400

    anime({
      targets: next,
      opacity: [0, 1],
      translateX: [forward ? '30%' : '-30%', 0],
      easing: 'easeOutCubic',
      delay: initDelay,
      duration: 500
    })

    this.current = next

    if (this.refs.lookId) this.refs.lookId.innerText = text.padStart(step + 1, '0', 2)

    // this.refs.previous.classList.toggle('disabled', step <= 0)
    // this.refs.next.classList.toggle('disabled', step >= this.modules.carousel.total - 1)
  }

  onPrevious = (event) => {
    this.modules.carousel.prev()
  }

  onNext = (event) => {
    this.modules.carousel.next()
  }

  flush () {
    this.close()
  }
}

export default CollectionPopin
