/* global dataLayer */
import detect from 'helpers/detect'
import store from 'store'
import { getRouterData } from 'navigation/component/Component'
import router from 'core/router'
import tag from 'core/tag'

import PageManager from './PageManager'

const decode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

const extractInfoFromXhr = (xhr) => {
  const auth = xhr.getResponseHeader('X-Fursac-Auth')
  const cart = xhr.getResponseHeader('X-Fursac-Cart')
  let error = xhr.getResponseHeader('X-Fursac-Error')
  let message = xhr.getResponseHeader('X-Fursac-Message')
  let events = xhr.getResponseHeader('X-Fursac-Events')
  const panel = xhr.getResponseHeader('X-Fursac-Panel') // || 'cart|http://jonas.local:8080/fr/commande/panier.html'

  if (error) error = decode(error)
  if (message) message = decode(message)
  if (events) events = atob(events)

  if (!xhr.infoChecked) {
    if (auth) store.logged.set(!!parseInt(auth))
    if (cart) store.cart.set(parseInt(cart))
    if (panel) {
      const routerData = getRouterData(panel)
      if (routerData.tagName) tag.openPopin(routerData.tagName, 'panel')
      if (routerData.path) {
        setTimeout(() => {
          store.panel.set(routerData.path.replace(router.root, ''))
        }, 300)
      }
    }
  }

  const page = xhr.response
  const obj = extractInfo(page, { error, message })
  if (obj.message) message = obj.message
  if (obj.error) error = obj.error
  if (obj.events) events = obj.events

  if (events) executeTag(events)

  xhr.infoChecked = true

  return {
    panel,
    auth,
    cart,
    error,
    message,
    events
  }
}

function executeTag (tag) {
  if (tag) eval(tag) // eslint-disable-line no-eval
}

function extractInfo (page, { error = null, message = null } = {}) {
  let events = ''

  if (page) {
    if (!error || !message) {
      const errorMessage = page.querySelector('.error-message')
      const successMessage = page.querySelector('.success-message')

      if (errorMessage) {
        error = errorMessage.innerHTML
        errorMessage.parentNode.removeChild(errorMessage)
      } else if (successMessage) {
        message = successMessage.innerHTML
        successMessage.parentNode.removeChild(successMessage)
      }
    }

    const ga4Events = page.querySelector('script#ga4-events')
    if (ga4Events) {
      events = ga4Events.innerHTML
      ga4Events.parentNode.removeChild(ga4Events)
    }
  }

  if (error || message) {
    store.message.set({
      error: !!error,
      text: error || message
    })
  }

  return {
    error,
    message,
    events
  }
}

class CustomPageManager extends PageManager {
  initializePage () {
    super.initializePage(...arguments)
    extractInfo(this.page.current.el)
  }

  pageLoaded (pathName, xhr, requestOptions) {
    extractInfoFromXhr(xhr)

    const currentRouter = super.pageLoaded(...arguments)

    if (currentRouter === true && this.state.next !== 'CheckoutSummary') {
      try {
        blueshift.pageload() // eslint-disable-line no-undef
        const skus = xhr.getResponseHeader('X-Fursac-SKUs')
        if (skus) blueshift.track('view', { products: JSON.parse(atob(skus)) }) // eslint-disable-line no-undef
      } catch (error) {}
    }
  }

  extractPageFromXHR (el) {
    if (detect.safari || detect.firefox) {
      if (!this.tmpDiv) this.tmpDiv = document.createElement('div')
      this.tmpDiv.innerHTML = '' + el.innerHTML
      return super.extractPageFromXHR(this.tmpDiv)
    }
    return super.extractPageFromXHR(...arguments)
  }
}

export { extractInfoFromXhr, extractInfo, executeTag }

export default CustomPageManager
