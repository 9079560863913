import Carousel from 'components/carousel/Carousel'
import Component from 'navigation/component/Component'

class ArticleCarousel extends Component {
  constructor (el) {
    super(el)
    this.bindRefs()
    this.bindModules()
    this.updateWidth()
  }

  bindModules () {
    super.bindModules()
    this.modules.carousel.duration = 450
    this.modules.carousel.easing = 'easeInOutQuad'
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.previous[method]('click', this.prev)
    this.refs.next[method]('click', this.next)
    this.refs.carouselImages.forEach(i => i[method]('load', this.updateWidth))
    this.modules.carousel[add ? 'on' : 'off']('update', this.onChange)
  }

  prev = () => {
    this.modules.carousel.prev(true)
  }

  next = () => {
    this.modules.carousel.next(true)
  }

  onChange = (step) => {
    this.updateWidth()
  }

  resize () {
    super.resize()
    this.updateWidth()
  }

  updateWidth () {
    const { carousel } = this.modules
    const item = carousel.items[carousel.step.current]
    const w = Math.floor(item.offsetWidth) - 1
    if (!w) return
    this.el.style.setProperty('--width', w + 'px')
  }

  getModuleMap () {
    return {
      carousel: ['.article-carousel__content', Carousel]
    }
  }
}

export default ArticleCarousel
