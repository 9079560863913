import { defer, pickBy, omit } from 'lodash'

import InternalPage from 'navigation/pages/InternalPage'

class CheckoutAddress extends InternalPage { 
 static pageName = 'CheckoutAddress'; 
  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const method = add ? 'on' : 'off'
    this.modules.radioGroup.forEach(r => r[method]('change', this.onMethodChange, this))
    this.onMethodChange()
  }

  bindModules () {
    super.bindModules()
    const { form } = this.modules

    const validations = Object.assign({}, form.validations)
    const billingValidation = pickBy(validations, (v, k) => !!~k.indexOf('billing___'))
    const deliveryValidation = pickBy(validations, (v, k) => !!~k.indexOf('delivery___'))
    const baseValidation = omit(validations, [...Object.keys(billingValidation), ...Object.keys(deliveryValidation)])

    this.validations = {
      default: validations,
      billing: billingValidation,
      delivery: deliveryValidation,
      base: baseValidation
    }
  }

  onMethodChange (value, name, input) {
    defer(() => {
      const data = this.modules.radioGroup.reduce((memo, radio) => {
        memo[radio.name] = radio.value
        return memo
      }, {})

      const schema = { ...this.validations.base }
      const { form } = this.modules

      if (data.address_choice === 'new') Object.assign(schema, this.validations.delivery)
      if (data.same_address === '0') Object.assign(schema, this.validations.billing)

      form.updateSchema(schema)
      setTimeout(() => form.quickValid(), 10)
    })
  }

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
  }
}

export default CheckoutAddress
