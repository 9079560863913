
import anime from 'animejs'

import scroll from 'core/scroll'
import browser from 'helpers/browser'
import math from 'helpers/math'
import resize from 'helpers/resize'
import Component from 'navigation/component/Component'
import sizeStore from 'store/sizeStore'

class ParallaxImage extends Component {
  constructor (el) {
    super(...arguments)
    this.showProgress = -1
    this.wrapImage()
  }

  wrapImage () {
    this.wrapper = document.createElement('div')
    this.wrapper.className = 'parallax-image__wrapper ' + this.el.className.replace('parallax-image', '')
    this.el.className = 'parallax-image'
    this.el.parentNode.insertBefore(this.wrapper, this.el)
    this.wrapper.appendChild(this.el)
  }

  bindEvents (add = true) {
    scroll[add ? 'on' : 'off'](this.onScroll)
    if (add) this.tick()
    else window.cancelAnimationFrame(this.raf)
  }

  show () {
    return anime({
      targets: this,
      showProgress: 0,
      easing: 'easeInOutCubic',
      duration: 1200,
      update: () => (this.dirty = true)
    })
  }

  tick = () => {
    this.raf = window.requestAnimationFrame(this.tick)
    if (!this.dirty) return
    this.dirty = false
    const offset = math.clamp(this.animProgress + this.showProgress, -1, 1)
    this.el.style.transform = `scale(1.1) translateY(${10 * offset}%)`
  }

  onScroll = () => {
    let progress = (this.getScrollTop() - this.top) / this.height
    progress = Math.min(1, Math.max(0, progress))
    const anim = (progress - .5) * 2

    if (anim === this.animProgress) return
    this.dirty = true
    this.animProgress = anim
  }

  getScrollTop () {
    const minHeight = sizeStore.notificationHeight.get()
    return !this.shown ? Math.min(minHeight, scroll.scrollTop()) : scroll.scrollTop()
  }

  transitionComplete () {
    this.resize()
    setTimeout(() => {
      this.shown = true
    }, 100)
  }

  resize () {
    super.resize()
    const height = this.el.offsetHeight
    this.top = browser.offsetTop(this.el, (el) => {
      if (el.classList.contains('appearing'))
        return Math.min(sizeStore.notificationHeight.get(), document.scrollingElement.scrollTop)
    }) - resize.height()
    this.height = height + resize.height()
    this.onScroll()
  }
}

export default ParallaxImage
