import tag from 'core/tag'
import { createStore } from 'helpers/state'

const store = createStore({

  lang: 'fr',
  path: null,
  loading: 1,
  message: null,

  // CHECKOUT
  checkoutStep: null,
  checkoutPickup: null,

  // ROUTERS
  routers: {},
  menu: null,
  panel: null,
  search: null,
  popin: null,

  // HEADERS
  cart: null,
  logged: null,
  alternateLinks: null,
  darkPage: false,
  darkMode: false,
  // PRODUCT
  lastProduct: null
})

store.menu.listen((value) => { if (!value) tag.closePopin('menu') })
store.search.listen((value) => { if (!value) tag.closePopin('search') })
store.panel.listen((value) => { if (!value) tag.closePopin('panel') })
store.popin.listen((value) => { if (!value) tag.closePopin('popin') })

export default store
