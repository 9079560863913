import Component from 'navigation/component/Component'

class ProductColors extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.page = 0
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.productColorsPrev[method]('click', this.prev)
    this.refs.productColorsNext[method]('click', this.next)
    // this.refs.productInfoToggle.forEach(button => button[method]('click', this.onClickMobileInfo))
  }

  bindRefs () {
    super.bindRefs()
    this.refs.productColorsInner = this.refs.productColorsWrapper.firstElementChild
    this.refs.productColorsItems = Array.from(this.refs.productColorsInner.children)
  }

  prev = () => {
    this.page--
    this.updateTransform()
    this.updateArrows()
  }

  next = () => {
    this.page++
    this.updateTransform()
    this.updateArrows()
  }

  updateArrows () {
    this.refs.productColorsPrev.classList.toggle('visible', this.hasArrow && this.page > 0)
    this.refs.productColorsNext.classList.toggle('visible', this.hasArrow && this.page < (this.computed.totalPage - 1))
  }

  updateTransform () {
    const child = this.refs.productColorsInner
    if (!this.hasArrow) {
      child.style.transform = ''
      this.refs.productColorsItems.forEach((item, key) => {
        item.classList.toggle('fadeOut', false)
      })
    } else {
      let offset = -this.page * this.computed.pageWidth
      if (this.page > 0) offset += this.computed.arrowWidth
      child.style.transform = `translateX(${offset}px)`

      const min = this.page * this.computed.itemByPage
      const max = (this.page + 1) * this.computed.itemByPage
      this.refs.productColorsItems.forEach((item, key) => {
        const hidden = key < min || key >= max
        item.classList.toggle('fadeOut', hidden)
      })
    }
  }

  resize () {
    const wrapper = this.refs.productColorsWrapper
    const inner = this.refs.productColorsInner
    const items = this.refs.productColorsItems

    const wrapperWidth = wrapper.offsetWidth
    const innerWidth = inner.offsetWidth
    const hasArrow = wrapperWidth < innerWidth

    this.computed = {}
    if (items.length > 1) {
      const w = items[1].getBoundingClientRect().left - items[0].getBoundingClientRect().left

      this.computed.itemByPage = Math.floor(wrapperWidth / w)
      this.computed.arrowWidth = this.refs.productColorsPrev.getBoundingClientRect().width
      this.computed.pageWidth = (this.computed.itemByPage * w)
      this.computed.totalPage = Math.ceil(innerWidth / this.computed.pageWidth)
    }

    if (hasArrow !== this.hasArrow) {
      this.hasArrow = hasArrow
      this.el.classList.toggle('has-arrow', hasArrow)
    }
    this.updateTransform()
    this.updateArrows()

    super.resize()
  }
}

export default ProductColors
