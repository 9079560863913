import MainPage from 'navigation/pages/MainPage'

import HomeBlocks from './partials/HomeBlocks'

class Home extends MainPage { 
 static pageName = 'Home'; 
  getModuleMap () {
    return {
      ...super.getModuleMap(),
      homeBlocks: ['.home__blocks', HomeBlocks]
    }
  }
}

export default Home
