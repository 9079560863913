
import Component from 'navigation/component/Component'
import Form from 'components/form/Form'

class CheckoutProduct extends Component {
  getModuleMap = () => ({
    form: ['.checkout-product__form', Form]
  })

  constructor (el, { parent }) {
    super(...arguments)
    this.parent = parent
    this.bindRefs()
    this.bindModules()
  }

  getModuleParams () {
    return {
      parent: this.options.parent
    }
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    if (this.refs.checkoutProductSelect)
      this.refs.checkoutProductSelect.forEach(select => select[method]('change', this.onSelectChange))
  }

  onSelectChange = (event) => {
    // const input = event.currentTarget
    this.modules.form && this.modules.form.submit()
  }
}

export default CheckoutProduct
