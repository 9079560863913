
import MainPage from 'navigation/pages/MainPage'

import ArticleCarousel from './partials/ArticleCarousel'
import ArticleGallery from './partials/ArticleGallery'
import ArticleImage from './partials/ArticleImage'

class Article extends MainPage { 
 static pageName = 'Article'; 
  getModuleMap () {
    return {
      ...super.getModuleMap(),
      image: ['.article-image', ArticleImage],
      carousel: ['.article-carousel', ArticleCarousel],
      gallery: ['.article-gallery', ArticleGallery]
    }
  }

  bindModules () {
    super.bindModules(...arguments)
  }
}

export default Article
