import InternalPage from 'navigation/pages/InternalPage'

class CheckoutPersonalDetails extends InternalPage { 
 static pageName = 'CheckoutPersonalDetails'; 
  bindEvents (add = true) {
    super.bindEvents(...arguments)
    // const method = add ? 'addEventListener' : 'removeEventListener'
  }

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
  }
}

export default CheckoutPersonalDetails
