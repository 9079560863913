import mapboxgl from 'mapbox-gl'

import config from 'core/config'
import 'mapbox-gl/dist/mapbox-gl.css'
import Component from 'navigation/component/Component'

mapboxgl.accessToken = config.mapbox

class Map extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.map = new mapboxgl.Map({
      container: this.el,
      style: 'mapbox://styles/fursac/ckwc0iwoo0n6v14qrs6tkn1ms'
      // style: 'mapbox://styles/mapbox/light-v10'
    })
  }

  zoomIn = () => this.map && this.map.zoomIn()

  zoomOut = () => this.map && this.map.zoomOut()

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const methodNative = add ? 'addEventListener' : 'removeEventListener'
    this.refs.zoomOut[methodNative]('click', this.zoomOut)
    this.refs.zoomIn[methodNative]('click', this.zoomIn)
  }

  deleteMarkersAndPopUp = (markers, popups) => {
    setTimeout(() => {
      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i].getElement()
        marker.onclick = null
        popups[i].remove()
        markers[i].remove()
      }
    }, 1000)
  }

  createMarkersAndPopups = (points) => {
    if (this.markers) this.deleteMarkersAndPopUp(this.markers, this.popups)
    this.markers = []
    this.popups = []

    points.forEach(([point, text, button], i) => {
      const wrapper = document.createElement('div')
      wrapper.innerHTML = text
      if (button) wrapper.appendChild(button)

      const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(wrapper)
      this.popups.push(popup)
      const el = document.createElement('div')
      el.className = 'map__marker'
      el.onclick = this.onMarkerClicked(i)

      const marker = new mapboxgl.Marker(el)
        .setLngLat(point)
        .addTo(this.map)
        .setPopup(popup)
      this.markers.push(marker)
    })
  }

  onMarkerClicked = i => event => {
    this.emit('clicked', i)
  }

  setMarkers (points) {
    const bounds = new mapboxgl.LngLatBounds()
    points.forEach(p => bounds.extend(p[0]))

    const options = { padding: 100, maxZoom: 12 }

    if (points.length) {
      if (!this.markers) options.animate = false
      this.map.fitBounds(bounds, options)
    }

    this.createMarkersAndPopups(points)
  }
}

export default Map
