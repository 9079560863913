import SwipeCarousel from 'components/carousel/SwipeCarousel'
import CollectionPopin from 'sections/lookbooks/partials/CollectionPopin'

class ArticleGalleryPopin extends CollectionPopin {
  getModuleMap () {
    return {
      carousel: ['.article-gallery__popin-inner', SwipeCarousel]
    }
  }

  bindRefs () {
    super.bindRefs(...arguments)
  }
}

export default ArticleGalleryPopin
