import anime from 'animejs'

const staggerItems = (targets, { initialDelay = 0, stagger = 50, hide = false, duration = 500, ...props } = {}) => {
  return anime({
    targets,
    opacity: {
      value: hide ? [1, 0] : [0, 1],
      easing: 'linear'
    },
    duration,
    delay: (el, i) => {
      return i * stagger + initialDelay
    },
    translateY: {
      value: hide ? [0, -10] : [10, 0],
      easing: hide ? 'easeInCubic' : 'easeOutCubic'
    },
    ...props
  }).finished
}

export {
  staggerItems
}
