
import anime from 'animejs'

import Carousel from 'components/carousel/Carousel'
import scroll from 'core/scroll'
import browser from 'helpers/browser'
import detect from 'helpers/detect'
import Component from 'navigation/component/Component'

class SwipeCarousel extends Component {
  constructor (el) {
    super(...arguments)
    this.items = Array.from(el.children)
    this.current = 0
    this.autoplay = false
  }

  deferredInit () {
    super.deferredInit()
    this.total = this.items.length

    if (this.items[0]) this.items[0].classList.add('show')
  }

  enableKeyboard () {
    this._keyboardEnabled = true
  }

  enable () {
    this._enabled = true
    this.resize()
  }

  disable () {
    this._enabled = false
  }

  onKeyDown = ({ key }) => {
    if (!this._enabled || !this._keyboardEnabled) return
    if (key === 'ArrowLeft') this.prev()
    else if (key === 'ArrowRight') this.next()
  }

  bindEvents (add = true) {
    if (this.total <= 1) return

    const method = add ? 'addEventListener' : 'removeEventListener'

    window[method](!detect.touch ? 'mousemove' : 'touchmove', this.mousemove, { passive: false })
    this.el[method](!detect.touch ? 'mousedown' : 'touchstart', this.mousedown)
    window[method](!detect.touch ? 'mouseup' : 'touchend', this.mouseup)
    window[method]('keydown', this.onKeyDown)

    if (this.autoplay) {
      if (add) this.interval = setInterval(this.tick, 5000)
      else clearInterval(this.interval)
    }
  }

  tick = () => {
    this.next()
  }

  getFrame (event) {
    return {
      time: Date.now(),
      y: (event.pageY - scroll.scrollTop()),
      x: event.pageX
    }
  }

  hasScrolled () {
    return Carousel.prototype.hasScrolled.call(this, ...arguments)
  }

  hasClicked () {
    return Carousel.prototype.hasClicked.call(this, ...arguments)
  }

  mousedown = (event) => {
    // event.preventDefault()
    if (this._enabled === false) return

    clearInterval(this.interval)
    event = browser.mouseEvent(event)
    const frame = this.getFrame(event)
    this.scrollLocked = false
    this.frames = { first: frame, history: [frame] }
  }

  mousemove = (event) => {
    if (this._enabled === false || !this.frames) return

    const mouseEvent = browser.mouseEvent(event)
    const frame = this.getFrame(mouseEvent)
    const { first } = this.frames

    if (this.scrollLocked || this.hasScrolled(frame, first)) event.preventDefault()
    else return

    event && event.stopImmediatePropagation()

    this.frames.history.push(frame)
    while (this.frames.history.length > 5) this.frames.history.shift()
  }

  mouseup = (event) => {
    if (this._enabled === false || !this.frames) return

    event = browser.mouseEvent(event)

    const frame = this.getFrame(event)
    const { first, history } = this.frames
    const last = history[0]
    if (this.hasClicked(frame, first)) this.click(event, event.target)

    if (!this.frames) return // recheck here

    const speed = (frame.x - last.x) / (frame.time - last.time)

    if (Math.abs(speed) > 0.05) {
      if (speed > 0) this.prev()
      else this.next()
    }

    this.frames = null
  }

  click (event, target) {

  }

  next = (event) => {
    if (event) clearInterval(this.interval)
    let next = this.current + 1
    if (next >= this.total) next = 0
    this.animate(next, true)
  }

  prev = () => {
    clearInterval(this.interval)
    let previous = this.current - 1
    if (previous < 0) previous = this.total - 1
    this.animate(previous, false)
  }

  goTo (next, force = false) {
    const previous = this.current
    const currentItem = this.items[previous]
    const nextItem = this.items[next]
    anime.remove([currentItem, nextItem])

    currentItem.classList.remove('show')
    nextItem.classList.add('show')
    nextItem.style.opacity = 1
    nextItem.style.transform = ''

    this.current = next
    this.emit('update', next, true)
  }

  animate (next, forward) {
    const previous = this.current
    const currentItem = this.items[previous]
    const nextItem = this.items[next]

    anime.remove([currentItem, nextItem])

    nextItem.classList.add('show')

    nextItem.style.zIndex = '20'
    currentItem.style.zIndex = ''

    const tl = anime.timeline({
      easing: 'easeOutCubic',
      duration: 500
    })

    this.emit('update', next, forward)

    tl.add({
      targets: currentItem,
      translateX: forward ? '-8%' : '8%',
      opacity: [0],
      complete: () => {
        currentItem.classList.remove('show')
      }
    }, 0)

    const initDelay = detect.desktop ? 200 : 400

    tl.add({
      targets: nextItem,
      translateX: [forward ? '8%' : '-8%', '0%'],
      opacity: [0, 1],
      delay: initDelay
    }, 0)

    this.current = next

    return tl
  }

  flush () {
    super.flush()
  }
}

export default SwipeCarousel
