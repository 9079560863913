import Emitter from 'tiny-emitter'
import anime from 'animejs'

import 'scrolling-element'
import detect from 'helpers/detect'

let scrollTop = document.scrollingElement.scrollTop
let preventScroll = false
let disableOverflow = false

const emitter = new Emitter()

document.scrollingElement.classList.add('scrolling-element')

const init = () => {
  window.addEventListener('scroll', pageScroll, { passive: false })

  document.body.addEventListener('touchmove', preventDefault, { passive: false })
  document.body.addEventListener('mousewheel', preventDefault, { passive: false })
  document.body.addEventListener('wheel', preventDefault, { passive: false })
  document.body.addEventListener('DOMMouseScroll', preventDefault, { passive: false })
}

const preventDefault = (event) => {
  if (preventScroll) {
    // const { target } = event

    // let scrollable
    // if (target._scrollable !== undefined) {
    //   scrollable = target._scrollable
    // } else {
    //   scrollable = !!parseInt(window.getComputedStyle(event.target).getPropertyValue('--no-prevent-scroll'))
    //   target._scrollable = scrollable
    // }

    // if (!scrollable)
    event.preventDefault()
  }
}

const lock = (overflow = false) => {
  if (overflow) {
    if (document.documentElement.classList.contains('no-scroll')) return
    const scrollTop = document.documentElement.scrollTop
    document.documentElement.classList.add('no-scroll')
    if (detect.desktop) document.body.scrollTop = scrollTop
    disableOverflow = true
  } else { preventScroll = true }
}

const unlock = (overflow = false) => {
  emitter.emit('unlock')
  if (overflow) {
    if (!document.documentElement.classList.contains('no-scroll')) return
    const scrollTop = document.body.scrollTop
    document.documentElement.classList.remove('no-scroll')
    if (detect.desktop) document.documentElement.scrollTop = scrollTop
    disableOverflow = false
  } else { preventScroll = false }
}

const pageScroll = (event) => {
  const _scrollTop = document.scrollingElement.scrollTop
  if (_scrollTop === scrollTop) return

  testScroll(_scrollTop)
  scrollTop = _scrollTop
  emitter.emit('scroll', event)
}

let startScrollDown = false
let hasScrollDown = false
let lastScrollTop = Infinity
const scrollClassname = 'scroll-down'
const topOffset = 100
const offset = 50

const testScroll = (newScrollTop) => {
  if (newScrollTop === scrollTop) return
  if (newScrollTop > Math.max(0, scrollTop)) scrollDown(newScrollTop)
  else scrollUp(newScrollTop)
}

const scrollDown = (newScrollTop) => {
  if (!startScrollDown) {
    startScrollDown = true
    lastScrollTop = newScrollTop
  }

  if (hasScrollDown || Math.abs(lastScrollTop - newScrollTop) < offset) return
  document.body.classList.add(scrollClassname)

  lastScrollTop = newScrollTop
  hasScrollDown = true
}

const scrollUp = (newScrollTop) => {
  if (startScrollDown) {
    startScrollDown = false
    lastScrollTop = newScrollTop
  }

  if (!hasScrollDown || (Math.abs(lastScrollTop - newScrollTop) < offset && newScrollTop > topOffset)) return
  document.body.classList.remove(scrollClassname)
  hasScrollDown = false
}

const scrollTo = (_scrollTop, { duration = 800, easing = 'easeOutQuad', target } = { }) => {
  // if (animating) return Promise.resolve()
  return anime({
    targets: target || document.scrollingElement,
    scrollTop: [target ? target.scrollTop : scrollTop, _scrollTop],
    // scrollTop: _scrollTop,
    duration,
    easing,
    // complete: () => (animating = false),
    update: () => pageScroll({})
  }).finished
}

const getScrollTop = () => {
  if (detect.desktop && disableOverflow) return document.body.scrollTop
  return scrollTop
}

const scroll = {
  init,
  lock,
  unlock,
  locked: () => (preventScroll || disableOverflow),
  scrollTo,
  on: (cb) => emitter.on('scroll', cb),
  off: (cb) => emitter.off('scroll', cb),
  emitter,
  scrollTop: getScrollTop,
  reset: () => unlock()
}

export default scroll
