import PanelPage from 'navigation/pages/PanelPage'
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import Component from 'navigation/component/Component'
import store from 'store/store'
import scroll from 'core/scroll'

class MenuPanel extends Component {
  constructor (el) {
    super(...arguments)
    this.el = el
    this.bindRefs()
    this.pageManager = new VirtualPageManager(store.menu, el.querySelector('.menu-panel__inner'), '.page', PanelPage)
  }

  bindEvents () {
    store.menu.listenAndStart(this.onMenuUpdate)
  }

  resize () {
    this.pageManager.resize()
    super.resize()
  }

  onMenuUpdate = (menu) => {
    const notEmpty = !!menu || menu === ''
    this.el.classList.toggle('opened', notEmpty)
    if (notEmpty) scroll.lock(true)
    else scroll.unlock(true)
  }
}

export default MenuPanel
