
import PanelPage from 'navigation/pages/PanelPage'
import Carousel from 'components/carousel/Carousel'

import CheckoutProduct from './partials/CheckoutProduct'

class Cart extends PanelPage { 
 static pageName = 'Cart'; 
  bindModules () {
    this.bindRefs()

    super.bindModules()
    if (this.modules.carousel)
      this.modules.carousel.enable()
  }

  getModuleMap () {
    return {
      ...super.getModuleMap(),
      carousel: ['.cart__carousel-wrapper', Carousel],
      products: ['.checkout-product', CheckoutProduct]
    }
  }

  bindEvents (add = true) {
    const method = add ? 'on' : 'off'
    const method2 = add ? 'addEventListener' : 'removeEventListener'

    if (this.modules.carousel) {
      this.modules.carousel[method]('update', this.onUpdate)
      this.onUpdate(0)
    }

    if (this.refs.cartDots) {
      this.refs.cartDots.forEach((dot) => {
        dot[method2]('click', this.onDotClicked)
      })
    }
  }

  onDotClicked = (event) => {
    if (!this.modules.carousel) return
    const id = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget)
    this.modules.carousel.animateToStep(id)
  }

  onUpdate = step => {
    if (!this.refs.cartDots) return
    this.refs.cartDots.forEach((dot, i) => {
      dot.classList.toggle('current', i === step)
    })
  }

  resize () {
    const total = this.el.querySelector('.checkout-total')
    if (total) this.el.style.setProperty('--sticky-offset', total.offsetTop + 'px')
    super.resize()
  }

  flush () {
    super.flush()
  }
}

export default Cart
