
import MainPage from 'navigation/pages/MainPage'
import InternalRouter from 'navigation/pages/InternalRouter'
import Map from 'components/map/Map'
import scroll from 'core/scroll'

class Stores extends InternalRouter { 
 static pageName = 'Stores'; 
  getModuleMap () {
    return {
      ...MainPage.prototype.getModuleMap.call(this),
      map: ['.stores__map-inner', Map]
    }
  }

  getPageSelector () {
    return '.stores__list'
  }

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const methodNative = add ? 'addEventListener' : 'removeEventListener'
    const methodSignal = add ? 'on' : 'off'
    if (this.refs.select) this.refs.select.forEach(select => select[methodNative]('change', this.onSelect))
    this.modules.content[methodSignal]('show', this.onPageShown)
    this.modules.map[methodSignal]('clicked', this.onMapClicked)
  }

  onMapClicked = (i) => {
    if (!this.items || !this.items[i]) return
    const scrollTop = this.items[i].offsetTop
    scroll.scrollTo(scrollTop)
  }

  onPageShown = (page) => {
    page.bindRefs()

    this.items = (page.refs.storeAddress || [])

    const points = this.items.map((el, i) => {
      const p = el.getAttribute('data-coords').split(',').map(a => +a)
      const text = page.refs.addressName[i].innerHTML
      return [p, text]
    })

    this.modules.map.setMarkers(points)
  }

  onSelect = ({ target }) => {
    this.modules.form && this.modules.form.submit()
  }

  internalRouting (pathName, xhr, requestOptions = {}) {
    const cities = xhr.response.getElementById('select__city')
    this.el.querySelector('#select__city').innerHTML = cities.innerHTML

    super.internalRouting(...arguments)
  }
}

export default Stores
