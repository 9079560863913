import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import SearchPage from 'navigation/pages/SearchPage'
import Component from 'navigation/component/Component'
import store from 'store/store'
import scroll from 'core/scroll'

class SearchPanel extends Component {
  constructor (el) {
    super(...arguments)

    this.el = el
    this.bindRefs()
    this.bindInternalRouter()

    this.pageManager = new VirtualPageManager(store.search, el.querySelector('.search-panel__inner'), '.page', SearchPage)
  }

  bindEvents () {
    store.search.listenAndStart(this.onSearchUpdate)
    this.refs.closeSearch.addEventListener('click', this.onClose)
    this.refs.overlaySearch.addEventListener('click', this.onClose)
    this.pageManager.on('show', this.updateHeight)
    this.pageManager.on('hide', this.updateHeight)
  }

  updateHeight = () => {
    const page = this.pageManager.page.current
    if (!page) return this.el.style.setProperty('--panel-height', '0px')
    this.el.style.setProperty('--panel-height', page.el.offsetHeight + 'px')
  }

  resize () {
    super.resize()
    this.pageManager.resize()
    this.updateHeight()
  }

  onClose = () => {
    store.search.set(null)
  }

  onSearchUpdate = (panel) => {
    const notEmpty = !!panel || panel === ''
    this.el.classList.toggle('opened', notEmpty)
    if (notEmpty) scroll.lock(true)
    else scroll.unlock(true)
  }
}

export default SearchPanel
