import Component from 'navigation/component/Component'

import ArticleGalleryPopin from './ArticleGalleryPopin'

class ArticleGallery extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.bindModules()
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.items.forEach((item, i) => item[method]('click', this.onItemClick(i)))
  }

  onItemClick = i => () => {
    this.modules.popin.open(i)
  }

  getModuleMap () {
    return {
      popin: ['.article-gallery__popin', ArticleGalleryPopin]
    }
  }
}

export default ArticleGallery
