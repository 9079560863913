
import InternalPage from 'navigation/pages/InternalPage'

class SizeGuide extends InternalPage { 
 static pageName = 'SizeGuide'; 
  bindEvents (add) {
    super.bindEvents(...arguments)
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.toggleUnits?.forEach((el) => {
      el[method]('click', this.toggleUnits)
    })
  }

  toggleUnits = (e) => {
    const button = e.currentTarget
    const unit = button?.getAttribute('data-unit')

    this.refs.toggleUnits?.forEach((el) => {
      el.classList.toggle('active', el === button)
    })

    this.el.setAttribute('data-unit', unit)
  }
}

export default SizeGuide
