import { zipObject } from 'lodash-es'

const _if = condition => new Promise((resolve, reject) => (condition ? resolve : reject)())
const wait = time => new Promise(resolve => setTimeout(resolve, time))
const pending = time => new Promise(resolve => {})

const defer = () => {
  const object = {}
  object.promise = new Promise((resolve, reject) => {
    object.resolve = resolve
    object.reject = reject
  })
  return object
}

const object = (data) => {
  const keys = Object.keys(data)
  return Promise.all(Object.values(data))
    .then((values) => {
      return zipObject(keys, values)
    })
}

export default {
  if: _if,
  pending,
  wait,
  defer,
  object
}
