
import { throttle } from 'lodash-es'

import resize from 'helpers/resize'
import Component from 'navigation/component/Component'

class ProductSelect extends Component {
  constructor (el, options) {
    super(...arguments)
    this.parent = options.parent
    this.bindRefs()
  }

  bindRefs () {
    super.bindRefs(...arguments)
    this.refs.radios = [...this.el.querySelectorAll('[type="radio"]')]
    this.detectValue()
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'

    this.refs.sizeBtn[method]('click', this.onSizeClick)
    this.refs.radios.forEach(radio => radio[method]('change', this.onInput))
    window[method]('click', this.onWindowClick)
    window[method]('scroll', this.onWindowScroll)
  }

  onWindowClick = (event) => {
    if (this.refs.sizeBtn === event.target || this.refs.radios.includes(event.target)) return
    this.el.classList.remove('size-opened')
  }

  onWindowScroll = throttle((event) => {
    this.resize()
  }, 500, { leading: true, trailing: true })

  onInput = (event) => {
    this.detectValue(event)
    this.onSizeClick()
  }

  detectValue = (event) => {
    const ref = this.refs.radios.find(radio => !!radio.checked)
    if (!ref) return
    this.refs.sizeBtn.innerHTML = ref?.parentNode?.querySelector('label')?.innerHTML
  }

  onSizeClick = (event) => {
    this.el.classList.toggle('size-opened')
  }

  resize () {
    super.resize()

    const bottom = this.el.getBoundingClientRect().bottom
    const maxHeight = Math.max(50, resize.height() - bottom - 20)
    this.refs.sizeList.style.setProperty('--max-height', maxHeight + 'px')

    this.refs.sizeList.style.setProperty('--select-height', Math.min(maxHeight, this.refs.sizeList.firstElementChild.offsetHeight) + 'px')
  }
}

export default ProductSelect
