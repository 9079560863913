import { defer } from 'lodash'
import anime from 'animejs'
import { without } from 'lodash-es'

import Component from 'navigation/component/Component'
import CategoriesList from 'components/categories-list/CategoriesList'
import store from 'store'
import SwitchButton from 'components/switch-button/SwitchButton'
import scroll from 'core/scroll'
import resize from 'helpers/resize'
import { staggerItems } from 'core/animations'

class QuickAccess extends Component {
  getModuleMap = () => ({
    ...super.getModuleMap(),
    categoriesList: ['.categories-list', CategoriesList],
    switchButton: ['.switch-button', SwitchButton]
  })

  constructor (el) {
    super(...arguments)
    this.collapsed = false
    this.container = document.querySelector('.container')
    this.bindModules()
    this.bindRefs()
  }

  show () {
    defer(this.defferedShow)
  }

  getAnimationTargets () {
    return this.refs.items && this.refs.items.reduce((memo, item) => {
      memo.push(item.querySelector('.categories-list__item-title'))
      if (item.classList.contains('opened'))
        memo.push(...Array.from(item.querySelectorAll('.categories-list__item-subtitle')))
      return memo
    }, [])
  }

  defferedShow = () => {
    const targets = this.getAnimationTargets()

    return staggerItems(targets, { initialDelay: 1200 })
      .then(() => {
        this.shown = true
        this.el.classList.add('shown')
      })
  }

  bindEvents () {
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.observerResize)
      this.resizeObserver.observe(this.container)
    }

    const pageManager = store.routers.get().main
    pageManager.on('show', this.onPageShow)
    pageManager.on('shown', this.onPageShown)
    store.search.listen(this.onPanelUpdate)
    store.menu.listen(this.onPanelUpdate)
    store.popin.listen(this.onPanelUpdate)
    store.panel.listen(this.onPanelUpdate)
    scroll.emitter.on('unlock', () => setTimeout(() => this.resize(), 100))

    if (pageManager.page.current) {
      this.onPageShow(pageManager.page.current)
      this.onPageShown(pageManager.page.current)
    }

    scroll.on(this.scroll)
  }

  onPanelUpdate = (value) => {
    if (!value) setTimeout(() => this.resize(), 100)
  }

  onPageShow = (page) => {
    // const pageName = page.pageName()
    // const openCategory = ~['Product', 'Category', 'Advices', 'Definitions'].indexOf(pageName)
    // if (!openCategory && this.modules.categoriesList) this.modules.categoriesList.reset()
  }

  onPageShown = (page) => {
    defer(() => {
      if (page.modules && page.modules.footer) this.footer = page.modules.footer.el
      else this.footer = null
      setTimeout(() => this.resize(), 200)
    })
  }

   scroll = (scrollTop) => {
     if (!this.shown) return

     const collapsed = scroll.scrollTop() > this.limit

     if (collapsed !== this.collapsed) {
       const targets = this.getAnimationTargets()
       const allLinks = this.refs?.links?.map(link => link?.parentNode) || []
       const others = without(allLinks, targets)

       anime.remove(targets)
       const options = collapsed
         ? { initialDelay: 0, duration: 80, stagger: 15, hide: true }
         : { initialDelay: 0 }

       staggerItems(targets, options)?.then(() => {
         if (!collapsed) {
           others?.forEach(item => {
             if (item?.style?.opacity === '0') {
               item.style.opacity = ''
               item.style.transform = ''
             }
           })
         }
       })

       this.collapsed = collapsed
       this.el.classList.toggle('inactive', collapsed)
     }
   }

    onMenuClicked = () => {
      this.el.classList.remove('collapsed')
    }

   observerResize = (e) => setTimeout(() => this.resize(), 50)

   resize () {
     if (scroll.locked()) return
     super.resize()
     if (this.footer) this.limit = document.body.clientHeight - this.footer.offsetHeight - (resize.height() * 3 / 4)
     else this.limit = Infinity
   }
}

export default QuickAccess
