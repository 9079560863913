import anime from 'animejs'

import Component from 'navigation/component/Component'
import store from 'store/store'

class LoadingBar extends Component {
  constructor (el) {
    super(...arguments)
    this.bindEvents()
    this.bindRefs()
  }

  bindEvents () {
    store.loading.listen(this.onLoadingUpdate)
  }

  onLoadingUpdate = (loading, previousValue) => {
    const duration = previousValue === 1 ? 0 : loading === 1 ? 200 : 3000

    if (previousValue === 0) this.showBar()

    anime.remove(this.el)

    anime({
      targets: this.el,
      scaleY: loading,
      easing: 'linear',
      duration,
      complete: loading === 1 && this.hideBar
    })
  }

  showBar = () => {
    this.el.style.display = 'block'
    this.el.style.opacity = 1
    this.el.style.transform = 'scaleY(0)'
    this.el.style.transformOrigin = 'bottom'
  }

  hideBar = () => {
    this.el.style.transformOrigin = 'top'

    anime({
      targets: this.el,
      scaleY: 0,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration: 700,
      complete: () => {
        this.el.style.display = 'none'
      }
    })
  }
}

export default LoadingBar
