
import resize from 'helpers/resize'
import Component from 'navigation/component/Component'

class HomeMedia extends Component {
  constructor () {
    super(...arguments)
    this.bindRefs()
  }

  resize () {
    super.resize()
    const portrait = resize.ratio() < 1
    const media = this.refs.homeMedia

    if (!media) return
    const srcPortrait = media.getAttribute('data-portrait')
    const srcLandscape = media.getAttribute('data-src')

    const src = portrait && srcPortrait ? srcPortrait : srcLandscape
    if (media.src !== src) {
      media.src = src
      media?.play?.()?.catch?.(() => {})
    }
  }
}

export default HomeMedia
