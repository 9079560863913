
import SwipeCarousel from 'components/carousel/SwipeCarousel'
import router from 'core/router'
import browser from 'helpers/browser'
import mqStore from 'store/mqStore'

class HomeCarousel extends SwipeCarousel {
  constructor (el) {
    super(...arguments)
    this.enable()
    this.bindRefs()
    this.autoplay = this.el.getAttribute('data-autoplay') !== 'false'
    if (mqStore.tabletPortrait.get()) this.refactorDyptich()
    this.updateButtonStatus()
  }

  refactorDyptich () {
    const diptych = this.refs.homeDyptich
    if (!diptych) return
    const diptychItems = diptych.querySelectorAll('.home-media')
    const carouselItem = diptych.parentNode
    if (diptychItems.length !== 2) return
    const [diptychItem, diptychItem2] = diptychItems
    carouselItem.removeChild(diptych)
    const cloneCarouselItem = carouselItem.cloneNode(true)
    carouselItem.appendChild(diptychItem)
    cloneCarouselItem.appendChild(diptychItem2)
    carouselItem.parentNode.insertBefore(cloneCarouselItem, carouselItem.nextSibling)
    this.refs.carouselItems.splice(this.refs.carouselItems.indexOf(carouselItem) + 1, 0, cloneCarouselItem)
  }

  deferredInit () {
    super.deferredInit()
    if (this.total) this.updateSrc(this.items[0], false)
    if (this.total === 1) this.items[0].classList.add('clickable')
  }

  updateButtonStatus () {
    if (this.items?.length <= 1) {
      if (this.refs.carouselPrev) this.refs.carouselPrev.style.display = 'none'
      if (this.refs.carouselNext) this.refs.carouselNext.style.display = 'none'
    }
  }

  bindRefs () {
    super.bindRefs(...arguments)
    this.items = this.refs.carouselItems
  }

  bindEvents (add = true) {
    if (this.total <= 1) return

    super.bindEvents(add)

    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.carouselPrev[method]('click', this.prev)
    this.refs.carouselNext[method]('click', this.next)

    this.items.forEach(item => item[method]('click', this.preventDefault))
  }

  preventDefault = event => {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()
  }

  updateSrc (item, clear) {
    const child = item.querySelector('[data-src]')
    if (!child) return
    const dataSrc = child.getAttribute('data-src')

    if (dataSrc) {
      if (clear && this.items[this.current] !== item) {
        const empty = child.tagName.toLowerCase() === 'iframe' ? 'about:blank' : ''
        child.src = empty
      } else {
        child.src = dataSrc
      }
    }
  }

  click (event, target) {
    if (target.tagName.toLowerCase() === 'button' || browser.isParent(target, this.el)) return
    const link = this.refs.carouselLabels[this.current]
    if (!link) return this.emit('click', event)
    const href = router.getLinkPath(link.firstElementChild)

    router.navigate(href)
  }

  getLabelChildren (label) {
    if (label.firstElementChild.tagName.toUpperCase() === 'A') return label.firstElementChild.children
    return label.children
  }

  animate (next, forward) {
    const previous = this.current
    const currentItem = this.items[previous]
    const nextItem = this.items[next]

    // const currentLabel = this.refs.carouselLabels[previous]
    // const nextLabel = this.refs.carouselLabels[next]

    const tl = super.animate(next, forward)

    // anime.remove([currentLabel, nextLabel])

    // nextLabel.style.display = 'flex'

    this.updateSrc(nextItem, false)

    // tl.add({
    //   targets: this.getLabelChildren(currentLabel),
    //   translateY: [0, 10],
    //   opacity: 0,
    //   complete: () => {
    //     if (this.current !== previous)
    //       currentLabel.style.display = 'none'
    //   }
    // }, 0)

    // tl.add({
    //   targets: this.getLabelChildren(nextLabel),
    //   translateY: [-10, 0],
    //   opacity: [0, 1],
    //   delay: (el, i) => i * 150 + initDelay
    // }, 0)

    this.current = next

    tl.finished.then(() => {
      this.updateSrc(currentItem, true)
    })

    return tl
  }

  flush () {
    super.flush()
  }
}

export default HomeCarousel
