
import anime from 'animejs'
import { defer } from 'lodash'

import PanelPage from 'navigation/pages/PanelPage'
import AbstractPage from 'navigation/pages/Page'

import CheckoutProduct from './CheckoutProduct'
import CheckoutFooter from './CheckoutFooter'

class CheckoutSummary extends PanelPage { 
 static pageName = 'CheckoutSummary'; 
  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.summaryButton[method]('click', this.toggleSummary)
  }

  getModuleMap () {
    return {
      ...super.getModuleMap(),
      products: ['.checkout-product', CheckoutProduct],
      checkoutFooter: ['.checkout-footer', CheckoutFooter]
    }
  }

  toggleSummary = () => {
    this.el.parentNode.classList.toggle('opened')
  }

  show (previousPage) {
    if (!previousPage) return AbstractPage.prototype.show.call(this, ...arguments)

    this.resize()

    return anime({
      targets: this.el,
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 400,
      delay: previousPage ? 100 : 0,
      complete: () => {
        defer(() => this.resize())
      }
    }).finished
  }

  hide (nextPage) {
    return anime({
      targets: this.el,
      opacity: [1, 0],
      easing: 'easeInQuad',
      duration: 400
    }).finished
  }
}

export default CheckoutSummary
