/* global FB, google */

import { each } from 'lodash'
import jwtDecode from 'jwt-decode'

import promise from 'helpers/promise'
import Component from 'navigation/component/Component'
import config from 'core/config'

let facebookInit = false
const facebookPromise = promise.defer()

class SocialLogin extends Component {
  constructor (el, { parent }) {
    super(...arguments)
    this.pageManager = parent.pageManager

    this.bindRefs()
    if (!facebookInit) this.initFacebook()
    this.initGoogle()
  }

  initGoogle () {
    if (!window.google) {
      setTimeout(this.initGoogle, 300)
      return
    }
    google.accounts.id.initialize({
      client_id: config.googleId,
      callback: this.onGoogleLogin
    })

    google.accounts.id.renderButton(this.refs.googleLogin, {
      theme: 'outline',
      size: 'large'
    })

    // gapi.load('auth2', () => {
    //   const auth2 = gapi.auth2.init({
    //     client_id: config.googleId,
    //     cookiepolicy: 'single_host_origin'
    //   })
    //   auth2.attachClickHandler(this.refs.googleLogin, {},
    //     this.onGoogleLogin,
    //     (error) => {
    //       console.error(JSON.stringify(error, undefined, 2)) //eslint-disable-line
    //     })
    // })
  }

  initFacebook () {
    const fjs = document.getElementsByTagName('script')[0]
    if (document.getElementById('facebook-jssdk')) return
    const js = document.createElement('script')
    js.id = 'facebook-jssdk'
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)

    facebookInit = true

    window.fbAsyncInit = function () {
      FB.init({
        appId: config.facebookId,
        cookie: true,
        xfbml: true,
        version: 'v18.0'
      })

      facebookPromise.resolve()
    }
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.facebookLogin[method]('click', this.onFacebookClicked)
    // this.refs.googleLogin[method]('click', this.onGoogleClicked)
  }

  onGoogleClicked = () => {
    if (!window.google) return
    google.accounts.id.prompt()
  }

  onFacebookClicked = () => {
    facebookPromise.promise.then(() => {
      FB.getLoginStatus((response) => {
        // if (response.status === 'connected') return this.onFBLogin(response)
        FB.login((response) => {
          if (response.status === 'connected') return this.onFBLogin(response)
        }, { scope: 'public_profile, email, user_gender' })
      })
    })
  }

  onFBLogin (response) {
    const accessToken = response.authResponse.accessToken

    FB.api('/me', { fields: 'last_name, first_name, email, gender' }, (response) => {
      this.sendData({
        facebook: true,
        token: accessToken,
        email: response.email,
        lastname: response.last_name,
        firstname: response.first_name
      })
    })
  }

  onGoogleLogin = (response) => {
    const profile = jwtDecode(response.credential)
    if (!profile) return

    this.sendData({
      google: true,
      token: response.credential,
      email: profile.email,
      lastname: profile.family_name,
      firstname: profile.given_name
    })
  }

  sendData (params) {
    const formData = new FormData()
    each(params, (value, key) => formData.append(key, value))

    const form = this.el.parentNode.querySelector('form')
    if (!form) return

    const action = form.getAttribute('action') || window.location
    const csrf = form.querySelector('[name="csrf_token"]')

    if (!csrf) return

    formData.append('csrf_token', csrf.value)

    this.pageManager.virtual(action, {
      body: formData, method: 'POST'
    })
  }
}

export default SocialLogin
